import { Component, Input } from '@angular/core';
import { ICompetitionMissingData } from '@beathletics/api-interfaces';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'beathletics-competition-missing-data',
    templateUrl: './competition-missing-data.component.html',
    imports: [TranslocoDirective]
})
export class CompetitionMissingDataComponent {
  @Input() data: ICompetitionMissingData[] | undefined | null;
  @Input() onlySchedule = false;
}
