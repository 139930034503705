import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IContact,
  IContactMail,
  IRegistrationConfirmationMailData,
  Mail,
  Roles,
  PrismaContactFunction as ContactFunction,
} from '@beathletics/api-interfaces';
import { NewCompetitionService } from '@beathletics/beathletics-data-state';
import { NotificationDialogComponent } from '@beathletics/beathletics-ui';
import { TranslocoService } from '@jsverse/transloco';
import { DialogService } from '../admin/shared/services/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class EmailManagementService {
  private http = inject(HttpClient);
  private transloco = inject(TranslocoService);
  private competitionService = inject(NewCompetitionService);
  private dialogService = inject(DialogService);
  private dialog = inject(MatDialog);
  private snackBar = inject(MatSnackBar);

  sendContactEmail(data: IContactMail) {
    return this.createContactEmail({
      ...data,
      subject: this.transloco.translate('CONTACT_SUBJECT_' + data.subject),
    }).subscribe({
      error: (err) => {
        this.dialog.open(NotificationDialogComponent, {
          data: {
            title: `Une erreur est survenue (${err?.error?.statusCode || 500})`,
            texts: [err.message || err.toString()],
          },
        });
      },
      complete: () => {
        this.snackBar.open('Votre message a bien été enregistré !', 'OK', {
          duration: 5000,
        });
      },
    });
  }

  sendRegistrationConfirmation(
    email: string,
    athletes: {
      fullName: string;
      club: string;
      bib: string | number | null;
      category: string;
      comment?: string;
      disciplines: {
        name: string;
        bestPerf: string | number;
      }[];
    }[],
  ) {
    const competition = this.competitionService.getSelectedCompetitionSnapshot();
    const data: IRegistrationConfirmationMailData = {
      contactEmail: email,
      eventName: competition.name,
      eventNumber: competition.eventNumber,
      eventDate: new Date(competition.startDate).toLocaleDateString(),
      organizerName: competition?.club?.name || competition?.federation?.name || 'ORGANIZER NAME NOT FOUND',
      organizerEmail:
        competition?.registrationDetail?.contactEmail ||
        (competition?.club ? competition.club.abbr.toLowerCase() + '@lbfa.be' : 'ORGANIZER EMAIL NOT FOUND'),
      organizerMsg: competition?.registrationDetail?.publicMsg || undefined,
      athletes,
      multipleAthletes: athletes.length > 1,
    };
    return this.createRegistrationConfirmationEmail(data).subscribe({
      error: (err) => this.dialogService.openNotificationDialog('Error ' + err.error.statusCode, [err.error.message]),
      complete: () => {
        this.snackBar.open(`Un email de confirmation a été envoyé à ${email}`, 'OK', {
          duration: 3000,
        });
      },
    });
  }

  sendCompetitionValidationEmail(contact: IContact) {
    if (contact.person.email) {
      const targetedRole = contact?.function === ContactFunction.Ja ? Roles.Ja : Roles.Homologation;
      const competition = this.competitionService.getSelectedCompetitionSnapshot();
      const mailData = {
        eventName: competition.name,
        eventNumber: competition.eventNumber,
        targetEmail: contact.person.email,
        targetFirstname: contact.person.firstName,
        targetLastname: contact.person.lastName,
      };

      this.createValidationMailToRole(mailData, targetedRole).subscribe({
        error: (err) => this.dialogService.openNotificationDialog('Error ' + err.error.statusCode, [err.error.message]),
        complete: () => {
          this.snackBar.open(`Email correctement envoyé à ${mailData.targetEmail} !`, 'OK');
          this.competitionService.reloadCompetitionLogs(mailData.eventNumber);
        },
      });
    } else {
      this.dialogService.openNotificationDialog('Error: no email', [
        "Le contact n'a pas d'email, et ça n'est pas censé arriver.",
        'Veuillez contactez les administrateurs pour corriger le problème.',
      ]);
    }
  }

  private createContactEmail = (data: IContactMail) => this.http.post<{ body: string }>(`/api/contact`, data);

  private createRegistrationConfirmationEmail = (data: IRegistrationConfirmationMailData) =>
    this.http.post<{ mailId: string }>('api/mail/registration/confirmation', data);

  private createValidationMailToRole = (data: Mail, role: Roles) =>
    this.http.post<Mail>(`api/mail/validation/${role}`, {
      email: data,
    });
}
