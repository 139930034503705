import { Injectable } from '@angular/core';
import {
  DisciplineGroups,
  EPerfType,
  EResultFormType,
  IDiscipline,
  IResult,
  PrismaDisciplineGroup as DisciplineGroup,
  PrismaValidation as Validation,
} from '@beathletics/api-interfaces';
import { CODES_HIGH_JUMPS, DISCIPLINE_GROUP_TO_FORM_TYPE } from '@beathletics/utils-shared';

@Injectable({
  providedIn: 'root',
})
export class CompetitionResultFormService {
  allowedSearchCategories?: string[];
  selectedAthletes?: {
    id: string;
    order: number | null;
    bib: number;
    clubId?: string;
    category: string;
  }[];
  temporaryCombinedEvents?: { total: IResult; children: IResult[] };

  getFormType(discipline: IDiscipline) {
    if (discipline?.disciplineGroup !== DisciplineGroup.Unknown) {
      return DISCIPLINE_GROUP_TO_FORM_TYPE[discipline.disciplineGroup];
    } else {
      const group = discipline.eventType?.discipline_group;
      if (group === DisciplineGroups.Saut && discipline.eventType?.national_code) {
        if (CODES_HIGH_JUMPS.includes(+discipline.eventType.national_code)) {
          return EResultFormType.HIGH_JUMP;
        } else {
          return EResultFormType.THROW_AND_LJ;
        }
      } else if (group === DisciplineGroups.Lancer) {
        return EResultFormType.THROW_AND_LJ;
      } else if (group === DisciplineGroups.Multiple) {
        return EResultFormType.COMBINED_TOTAL;
      } else {
        return EResultFormType.RACE;
      }
    }
  }

  getPerfTypeFromFormType(formType: EResultFormType) {
    if (formType === EResultFormType.RACE) {
      return EPerfType.TIME;
    } else if (formType === EResultFormType.COMBINED_TOTAL) {
      return EPerfType.POINTS;
    } else {
      return EPerfType.DISTANCE;
    }
  }

  setBaseResultData(result: IResult) {
    return {
      disciplineId: result.disciplineId,
      heatNumber: result.heatNumber,
      heatTimeOfficial: result.heatTimeOfficial,
      heatTimeScheduled: result.heatTimeScheduled,
      roundName: result.roundName,
      roundNumber: result.roundNumber,
      roundTimeOfficial: result.roundTimeOfficial,
      roundTimeScheduled: result.roundTimeScheduled,
      timerType: result.timerType,
      validationStatus: Validation.pending,
    } as IResult;
  }
}
