import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { TypedDialog } from '@beathletics/beathletics-ui';

@Component({
    selector: 'beathletics-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss'],
    imports: [MatButton]
})
export class AlertDialogComponent extends TypedDialog<{ text: string; title: string }, boolean> {
  alert = this.data;

  closeDialog(response: boolean) {
    this.dialogRef.close(response);
  }
}
