import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { IContact } from '@beathletics/api-interfaces';
import { NewCompetitionService } from '@beathletics/beathletics-data-state';
import { EmailManagementService } from '../../../../../shared/email-management.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { CompetitionMissingDataComponent } from '../competition-missing-data/competition-missing-data.component';
import { MatButton } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'beathletics-competition-imports-info-dialog',
    templateUrl: './competition-imports-info-dialog.component.html',
    imports: [TranslocoDirective, CompetitionMissingDataComponent, MatButton, MatDialogClose, AsyncPipe]
})
export class CompetitionImportsInfoDialogComponent {
  data = inject(MAT_DIALOG_DATA);
  private dialogRef = inject<MatDialogRef<CompetitionImportsInfoDialogComponent>>(MatDialogRef);
  private competitionService = inject(NewCompetitionService);
  private emailService = inject(EmailManagementService);

  missingData$ = this.competitionService.getCompetitionMissingDataFlags();

  reloadSchedule() {
    this.dialogRef.close(false);
  }

  activateRegistration() {
    this.dialogRef.close(true);
  }

  sendEmailToJa() {
    if (this.data.ja) {
      this.emailService.sendCompetitionValidationEmail(this.data.ja);
    }
  }
}
