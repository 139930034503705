import { Directive } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[beathleticsCustomInputValidators]',
})
export class CustomInputValidatorsDirective {}

export function priceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value && control.value !== 0) {
      return { required: { value: control.value } };
    } else if (control.value < 0) {
      return { negative: { value: control.value } };
    } else {
      const priceDecimals = control.value.toString().split('.')[1];
      if (priceDecimals && priceDecimals.length > 2) {
        return { precision: { value: control.value } };
      }
    }
    return null;
  };
}

export function resultPrecisionValidator(nbDecimals: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (nbDecimals < 5) {
      const precisionError = { resultPrecision: { value: control.value } };
      if (!control.value) {
        return { required: { value: control.value } };
      } else if (isNaN(+control.value)) {
        return null;
      } else if (nbDecimals === 0) {
        if (Number.isInteger(+control.value)) return null;
        else return precisionError;
      } else {
        const controlDecimals = control.value.toString().split('.')[1];
        if (controlDecimals) {
          const decimalLength = controlDecimals.length;
          if (decimalLength === nbDecimals) return null;
          else return precisionError;
        } else return precisionError;
      }
    } else return null;
  };
}

export function highJumpHeightValidator(regex: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const heightPrecisionError = { heightPrecision: { value: control.value } };
    if (!control.value) {
      return { required: { value: control.value } };
    } else {
      const test = regex.test(control.value);
      return test ? null : heightPrecisionError;
    }
  };
}

export function hightJumpNoDuplicateValidator(
  highJumpHeights: (number | string | undefined)[],
  heightToTest: string | null | undefined = '',
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const noDuplicateError = { noDuplicate: { value: control.value } };
    if (
      (highJumpHeights.includes(control.value) || highJumpHeights.includes(+control.value)) &&
      +control.value !== +(heightToTest || '0')
    ) {
      return noDuplicateError;
    } else return null;
  };
}
