import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { ICompetition } from '@beathletics/api-interfaces';
import { ApiServiceService } from '@beathletics/beathletics-data-state';
import { TranslocoPipe } from '@jsverse/transloco';
import { Subject, catchError, filter, map, of, tap } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'beathletics-event-search-bar',
  templateUrl: './event-search-bar.component.html',
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    MatIcon,
    MatIconButton,
    MatSuffix,
    MatProgressSpinner,
    TranslocoPipe, // pipe is used because searchInput is not found with the directive
  ],
})
export class EventSearchBarComponent {
  #router = inject(Router);
  #apiService = inject(ApiServiceService);

  isOpen = false;
  searchValue = new Subject<string>();
  currentSearchValue: string | undefined;
  searchAutocomplete = signal({
    competitions: [] as ICompetition[],
    loading: false,
    error: false,
  });
  subscription = this.searchValue
    .pipe(
      debounceTime(300),
      tap(() =>
        this.searchAutocomplete.update((value) => ({
          ...value,
          loading: true,
        })),
      ),
      filter((value) => !(value && value.length < 3)),
      switchMap((value) =>
        value
          ? this.#apiService.findEventByEventNumberOrName(value, this.limitedToClub).pipe(
              map((result) => ({ competitions: result, loading: false, error: false })),
              catchError(() => {
                return of({
                  competitions: [],
                  loading: false,
                  error: true,
                });
              }),
            )
          : of({
              competitions: [],
              loading: false,
              error: false,
            }),
      ),
      tap((value) => this.searchAutocomplete.set(value)),
    )
    .pipe(takeUntilDestroyed())
    .subscribe();

  @ViewChild('searchInput', { read: MatAutocompleteTrigger, static: true })
  searchInput!: MatAutocompleteTrigger;
  @ViewChild('searchInput', { static: true })
  searchNativeElem!: ElementRef<HTMLInputElement>;

  @Input() action: 'NAVIGATE' | 'RETURN' = 'NAVIGATE';
  @Input() appearance: 'fill' | 'outline' = 'fill';
  @Input() limitedToClub?: string;
  @Output() clicked = new EventEmitter<void>();
  @Output() eventToReturn = new EventEmitter<ICompetition>();

  onSearchChange(value: string) {
    if (value != this.currentSearchValue) {
      this.searchValue.next(value.trim());
      this.currentSearchValue = value.trim();
    }
    if (value.trim().length >= 3) {
      this.searchInput.openPanel();
      this.isOpen = true;
    } else {
      this.searchInput.closePanel();
      this.isOpen = false;
    }
  }

  onSearchSelect(value: ICompetition) {
    if (this.action === 'NAVIGATE') {
      if ((value?._count?.results || 0) > 0) {
        this.#router.navigate(['/', 'admin', 'competition', value.eventNumber, 'results']);
        this.clicked.emit();
      } else {
        this.#router.navigate(['/', 'admin', 'competition', value.eventNumber]);
        this.clicked.emit();
      }
    }
    if (this.action === 'RETURN') {
      this.eventToReturn.emit(value);
    }
  }

  setIsOpenParam(value: boolean) {
    this.isOpen = value;
  }

  retry() {
    this.searchValue.next(this.currentSearchValue || '');
  }
}
