import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators, ReactiveFormsModule } from '@angular/forms';

import { Roles } from '@beathletics/api-interfaces';
import type { Results } from '@prisma/client';
import { resultPrecisionValidator } from '../../../shared/custom-input-validators.directive';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
    selector: 'beathletics-result-edit-form-basic',
    templateUrl: './result-edit-form-basic.component.html',
    styleUrls: ['./result-edit-form-basic.component.scss'],
    imports: [
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatButton,
        MatIcon,
        MatError,
        TranslocoDirective,
        MatSelect,
        MatOption,
    ]
})
export class ResultEditFormBasicComponent {
  @Input() form!: FormGroup<any>;
  @Input() set result(result: Results) {
    this._result = JSON.parse(JSON.stringify(result));
  }
  @Input() showWind: boolean | undefined;
  @Input() resultPrecision: number | undefined;
  @Input() role: Roles | undefined | null;
  @Input() isFromCombinedEvent = false;

  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() resultForm = new EventEmitter<Results>();

  _result: Results | undefined;
  roles = Roles;
  validationStatus = Object.values(['validated', 'notValidated']);

  addNewResult() {
    if (this._result && this.form && this.resultPrecision) {
      const _res = this._result.result as any;
      this.form.addControl(
        'value' + (_res?.results?.length || 0),
        new FormControl('', [Validators.required, resultPrecisionValidator(this.resultPrecision)]),
      );
      if (this.showWind) {
        this.form.addControl('wind' + (_res?.results?.length || 0), new FormControl('', [Validators.required]));
      }
      _res.results.push({
        value: 0,
        wind: 0,
      });
    }
  }

  removeResult(result: any) {
    if (this._result && this.form) {
      const _res = this._result.result as any;
      const index = _res.results.indexOf(result);
      _res.results.splice(index, 1);
      this.form.removeControl('value' + index);
      if (this.showWind) {
        this.form.removeControl('wind' + index);
      }
    }
  }

  emitCloseDialog() {
    this.closeDialog.emit();
  }

  emitSaveResult() {
    if (this._result && this.form) {
      const form = { ...this.form.value };
      const newResult = JSON.parse(JSON.stringify(this._result));
      newResult.comment = form['comment'];
      newResult.result.currentOrder = form['position'];
      if (this.isFromCombinedEvent) {
        newResult.validation = form['validation'];
        newResult.result.points = form['points'];
      }
      const _res = this._result.result as any;
      if (_res?.results) {
        for (let i = 0; i < _res.results.length; i++) {
          newResult.result.results[i].value = form['value' + i];
          if (this.showWind) {
            newResult.result.results[i].wind = form['wind' + i];
          }
        }
      } else {
        newResult.result = {
          results: [
            {
              value: form['value0'],
            },
          ],
        };
        if (this.showWind) {
          newResult.result.results[0].wind = form['wind0'];
        }
      }

      this.resultForm.emit(newResult);
    }
  }

  getFormErrors() {
    if (this.form) {
      Object.keys(this.form.controls).forEach((key) => {
        const controlErrors: ValidationErrors | null | undefined = this.form?.get(key)?.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach((keyError) => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    }
  }
}
