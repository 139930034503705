import { Component, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AthleteSearchResult } from '@beathletics/api-interfaces';
import { ApiServiceService } from '@beathletics/beathletics-data-state';
import { Observable, Subject, catchError, debounceTime, filter, of, switchMap, tap } from 'rxjs';
import { BeathleticsUiModule } from '@beathletics/beathletics-ui';
import { MatCard, MatCardHeader, MatCardTitle, MatCardSubtitle, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { AsyncPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'beathletics-merge-athletes-dialog',
    templateUrl: './merge-athletes-dialog.component.html',
    imports: [
        BeathleticsUiModule,
        MatCard,
        MatCardHeader,
        MatCardTitle,
        MatCardSubtitle,
        MatCardContent,
        MatIcon,
        MatButton,
        MatDialogClose,
        MatProgressSpinner,
        AsyncPipe,
        DatePipe,
    ]
})
export class MergeAthletesDialogComponent implements OnInit {
  #apiService = inject(ApiServiceService);
  #dialogRef = inject(MatDialogRef<MergeAthletesDialogComponent>);
  #snackbar = inject(MatSnackBar);

  search: {
    [athlete: number]: {
      value: Subject<string>;
      autocomplete$: Observable<AthleteSearchResult[]>;
      loading: WritableSignal<boolean>;
      error: WritableSignal<boolean>;
      selected?: AthleteSearchResult;
    };
  } = {
    1: {
      value: new Subject<string>(),
      autocomplete$: of([]),
      loading: signal(false),
      error: signal(false),
      selected: undefined,
    },
    2: {
      value: new Subject<string>(),
      autocomplete$: of([]),
      loading: signal(false),
      error: signal(false),
      selected: undefined,
    },
  };
  processing = false;

  ngOnInit(): void {
    this.search[1].autocomplete$ = this.setAthleteSearchValue(1);
    this.search[2].autocomplete$ = this.setAthleteSearchValue(2);
  }

  setAthleteSearchValue(athleteNumber: 1 | 2) {
    return this.search[athleteNumber].value.pipe(
      tap(() => {
        this.search[athleteNumber].loading.set(true);
        this.search[athleteNumber].error.set(false);
      }),
      debounceTime(300),
      filter((value) => !(value && value.length < 2)),
      switchMap((value) =>
        value
          ? this.#apiService.findAthleteByNameOrLiveId(value).pipe(
              catchError(() => {
                this.search[athleteNumber].error.set(true);
                return of([]);
              }),
            )
          : of([]),
      ),
      tap(() => this.search[athleteNumber].loading.set(false)),
    );
  }

  mergeAthletes() {
    if (this.search[1].selected && this.search[2].selected) {
      this.processing = true;
      this.#apiService.mergeAthleteToAnother(this.search[1].selected.id, this.search[2].selected.id).subscribe({
        complete: () => {
          this.#snackbar.open('Athlètes correctement fusionnés', 'X', {
            duration: 5000,
          });
          this.#dialogRef.close();
        },
        error: (err) => {
          alert(JSON.stringify(err));
          this.#dialogRef.close();
        },
      });
    }
  }
}
