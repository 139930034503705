@for (f of forms; track f; let i = $index) {
  <h3 class="event-title">{{ i + 1 }}. {{ f.data.name }}</h3>
  @if (f.type === 'basic') {
    <beathletics-result-edit-form-basic
      [form]="f.form"
      [result]="f.data"
      [showWind]="f.showWind"
      [resultPrecision]="f.resultPrecision"
      [role]="role"
      [isFromCombinedEvent]="true"
      (closeDialog)="emitCloseDialog()"
      (resultForm)="emitSaveResult($event)"
      >
    </beathletics-result-edit-form-basic>
  }
  @if (f.type === 'high-jump' && highJumpData[f.data.name].length > 0) {
    <beathletics-result-edit-form-hj
      [form]="f.form"
      [result]="f.data"
      [highJumpData]="highJumpData[f.data.name]"
      [resultPrecision]="f.resultPrecision"
      [role]="role"
      [isFromCombinedEvent]="true"
      (closeDialog)="emitCloseDialog()"
      (resultForm)="emitSaveResult($event)"
      >
    </beathletics-result-edit-form-hj>
  }
}
