<section *transloco="let t">
  <h2 mat-dialog-title>{{ t('EXPORT_BIB_PDF') }}</h2>
  <mat-dialog-content class="!overflow-visible">
    <label>{{ t('REGISTRATION_BIBS_WHICH_TO_GENERATE') }}</label>
    <mat-radio-group
      color="primary"
      class="flex flex-col"
      [(ngModel)]="choice"
      (change)="setChoice($event)"
    >
      @for (possibility of possibilities; track possibility) {
        <mat-radio-button class="mt-2" [value]="possibility">{{
          t('REGISTRATION_BIBS_' + possibility, { n: data.bibs.length })
        }}</mat-radio-button>
      }
    </mat-radio-group>
    @if (choice === 'custom') {
      <beathletics-prime-list-selector
        class="my-1"
        selectionMode="checkbox"
        [label]="t('REGISTRATION_BIBS_TO_GENERATE')"
        [dataList]="bibList"
        [customOverview]="true"
        (selectionChange)="changeSelection($any($event))"
      ></beathletics-prime-list-selector>
    }
  </mat-dialog-content>
  <mat-dialog-actions class="flex !justify-between !mx-4">
    <button
      mat-raised-button
      color="primary"
      [disabled]="selectedBibs.length === 0"
      (click)="validateSelection()"
    >
      {{ t('BASIC_VALIDATE') }}
    </button>
    <button mat-raised-button color="warn" mat-dialog-close cdkFocusInitial>
      {{ t('BASIC_CANCEL') }}
    </button>
  </mat-dialog-actions>
</section>
