@if (competitionData) {
  <form
    [formGroup]="competitionData"
    class="flex flex-col"
    >
    <mat-form-field appearance="outline">
      <mat-label>Nom</mat-label>
      <input matInput formControlName="name" placeholder="Nom" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        @for (type of competitionsTypes; track type) {
          <mat-option [value]="type">
            {{ type }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Site</mat-label>
      <mat-select formControlName="venue">
        <mat-option value="Indoor"> Indoor </mat-option>
        <mat-option value="Outdoor"> Outdoor </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Date(s)</mat-label>
      <mat-date-range-input [rangePicker]="dateRangePicker">
        <input
          matStartDate
          formControlName="startDate"
          placeholder="Date de début"
          />
        <input matEndDate formControlName="endDate" placeholder="Date de fin" />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matSuffix
        [for]="dateRangePicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Organisateur</mat-label>
      <mat-select formControlName="organizer">
        <div>
          <mat-button-toggle-group
            [(ngModel)]="organizer"
            [ngModelOptions]="{ standalone: true }"
            (change)="organizerChange.emit($event.value)"
            class="w-full"
            >
            <mat-button-toggle value="club" class="w-1/2">Club</mat-button-toggle>
            <mat-button-toggle value="federation" class="w-1/2"
              >Fédération</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
          @if (organizer === 'club') {
            @for (club of clubs$ | async; track club) {
              <mat-option [value]="club.abbr">
                {{ club.abbr }} - {{ club.name }}
              </mat-option>
            }
          }
          @if (organizer === 'federation') {
            @for (federation of federations$ | async; track federation) {
              <mat-option
                [value]="federation.abbr"
                >
                {{ federation.abbr }} - {{ federation.name }}
              </mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Commentaire</mat-label>
        <textarea matInput formControlName="comment"></textarea>
      </mat-form-field>
    </form>
  }
