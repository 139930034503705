import { Injectable, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { verifyResultPrecision } from '@beathletics/utils-shared';
import type { Results } from '@prisma/client';
import { PrismaValidation as Validation } from '@beathletics/api-interfaces';
import {
  highJumpHeightValidator,
  hightJumpNoDuplicateValidator,
  resultPrecisionValidator,
} from './custom-input-validators.directive';

@Injectable({
  providedIn: 'root',
})
export class ResultEditFormService {
  private fb = inject(FormBuilder);

  highJumpHeightRegex = /^\d*[,.]\d{2}?$/;

  // * Basic Form

  setBasicEditForm(data: Results, resultPrecision: number, showWind: boolean, fromCombinedEvent = false) {
    const form = {} as Record<string, FormControl<any>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _result = data.result as any;
    form['position'] = new FormControl(_result?.currentOrder || _result?.results?.[0]?.place);
    form['comment'] = new FormControl(data.comment, [Validators.required, Validators.minLength(10)]);
    if (fromCombinedEvent) {
      form['points'] = new FormControl(_result.points);
      form['validation'] = new FormControl(data?.validation || Validation.pending);
    }
    // ! cas de résultat vide trouvé (_result = {})
    if (_result?.results) {
      for (let i = 0; i < _result.results.length; i++) {
        const res = _result.results[i]?.value || _result.results[i]?.score_distance || _result.results[i]?.time;
        const value = verifyResultPrecision(res, resultPrecision);
        form['value' + i] = new FormControl(value, [Validators.required, resultPrecisionValidator(resultPrecision)]);
        if (showWind) {
          form['wind' + i] = new FormControl(_result.results[i]?.wind || '', [Validators.required]);
        }
      }
    } else {
      form['value0'] = new FormControl('', [Validators.required, resultPrecisionValidator(resultPrecision)]);
      if (showWind) {
        form['wind0'] = new FormControl('', [Validators.required]);
      }
    }
    return this.fb.group(form);
  }

  // * High Jump Form

  setHighJumpEditForm(data: Results, resultPrecision: number, fromCombinedEvent = false) {
    const form = {} as Record<string, FormControl<any>>;
    const highJumpHeights = [] as string[];
    const highJumpResults = [] as any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _result = data.result as any;
    form['position'] = new FormControl(_result.currentOrder);
    form['code'] = new FormControl(_result?.alternative_code || null);
    form['comment'] = new FormControl(data.comment, [Validators.required, Validators.minLength(10)]);
    if (fromCombinedEvent) {
      form['points'] = new FormControl(_result.points);
      form['validation'] = new FormControl(data?.validation || Validation.pending);
    }
    for (const res of _result.results) {
      if (!highJumpHeights.includes(verifyResultPrecision(res?.metavalue, resultPrecision))) {
        highJumpHeights.push(verifyResultPrecision(res?.metavalue, resultPrecision));
      }
    }
    for (let i = 0; i < highJumpHeights.length; i++) {
      const result = {
        height: highJumpHeights[i],
        data: [] as any[],
        trials: [] as any[],
      };
      form['height' + result.height] = new FormControl(result.height, [
        highJumpHeightValidator(this.highJumpHeightRegex),
        hightJumpNoDuplicateValidator(highJumpHeights, result.height),
      ]);
      for (const res of _result.results) {
        if (verifyResultPrecision(res?.metavalue, resultPrecision) === highJumpHeights[i]) {
          const val = res?.value;
          result.trials.push(val);
          result.data.push(res);
          for (let j = result.trials.length - 1; j < 3; j++) {
            form['value' + result.height + '.' + j] = new FormControl(val);
          }
        }
      }
      if (result.trials.length < 3) {
        for (let k = result.trials.length; k < 3; k++) {
          result.trials.push('');
          result.data.push({});
          form['value' + result.height + '.' + k] = new FormControl('');
        }
      }
      highJumpResults.push(result);
    }
    return { name: data.name, highJumpResults, form: this.fb.group(form) };
  }

  // * Combined Events Form

  setCombinedEventsForm(data: Results, resultPrecision: number, showWind: boolean) {
    const form = {} as Record<string, FormControl<any>>;
    const _events = data.combinedEvents as any[];
    for (let i = 0; i < _events.length; i++) {
      form['position' + i] = _events[i].result.currentOrder;
      form['comment'] = new FormControl(data.comment, [Validators.required, Validators.minLength(10)]);
      for (let j = 0; j < _events[i].result.results.length; j++) {
        form['value' + i + '.' + j] = new FormControl(_events[i].result.results[j].value, [
          Validators.required,
          resultPrecisionValidator(resultPrecision),
        ]);
        if (showWind) {
          form['wind' + i + '.' + j] = new FormControl(_events[i].result.results[j].wind, [Validators.required]);
        }
      }
    }
    return this.fb.group(form);
  }
}
