<section *transloco="let t" class="max-w-[500px]">
  <header class="text-center">
    <h3 class="font-bold text-xl">
      @if (data.onlySchedule) {
        @if (
          $any(missingData$ | async)?.length > 0) {
          {{ t('REGISTRATION_SCHEDULE_NOT_OK') }}
        } @else {
          {{ t('REGISTRATION_SCHEDULE_DONE') }}
        }
      } @else {
        {{
        t('RESULT_UPLOAD_DONE')
        }}
      }
    </h3>
  </header>
  <hr class="my-4" />
  <div>
    @if ($any(missingData$ | async)?.length > 0) {
      <p class="text-center pb-4">
        @if (data.onlySchedule) {
          {{ t('REGISTRATION_SCHEDULE_SOME_DATA_MISSING') }}
        } @else {
          {{ t('RESULT_UPLOAD_SOME_DATA_MISSING') }}
        }
      </p>
      <div class="max-h-[300px] overflow-y-auto bg-slate-200 p-4">
        <beathletics-competition-missing-data
          [data]="missingData$ | async"
          [onlySchedule]="data.onlySchedule"
        ></beathletics-competition-missing-data>
      </div>
    } @else {
      <p class="text-center pt-2">
        @if (data.onlySchedule) {
          {{ t('REGISTRATION_SCHEDULE_NO_DATA_MISSING') }}
        } @else {
          {{ t('RESULT_UPLOAD_NO_DATA_MISSING') }}
        }
      </p>
    }
  </div>
  <div
    class="mt-4 flex"
    [style.justify-content]="data.onlySchedule ? 'center' : 'space-between'"
    >
    @if (data.onlySchedule) {
      @if ($any(missingData$ | async)?.length > 0) {
        <div class="flex flex-col items-center">
          <p
            [innerHtml]="t('REGISTRATION_SCHEDULE_EDIT_TO_CONTINUE')"
            class="text-center"
          ></p>
          <button
            mat-raised-button
            color="primary"
            class="!mt-4"
            (click)="reloadSchedule()"
            >
            {{ t('BASIC_UNDERSTOOD') }}
          </button>
        </div>
      } @else {
        <button
          mat-raised-button
          color="primary"
          class="!mt-4"
          (click)="activateRegistration()"
          >
          {{ t('REGISTRATION_ACTIVATE') }}
        </button>
      }
    } @else {
      <button mat-raised-button mat-dialog-close>
        {{ t('RESULT_VALIDATE_BUT_EDIT_FIRST') }}
      </button>
      <button
        mat-raised-button
        mat-dialog-close
        color="primary"
        (click)="sendEmailToJa()"
        >
        {{ t('RESULT_VALIDATE_SEND_JA_MAIL') }}
      </button>
    }
  </div>
</section>
