import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { Roles } from '@beathletics/api-interfaces';
import type { Results } from '@prisma/client';
import { ResultEditFormService } from '../../../shared/result-edit-form.service';
import {
  highJumpHeightValidator,
  hightJumpNoDuplicateValidator,
} from '../../../shared/custom-input-validators.directive';
import { HJ_RESULT_ALTERNATIVE_CODE } from '@beathletics/utils-shared';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'beathletics-result-edit-form-hj',
    templateUrl: './result-edit-form-hj.component.html',
    styleUrls: ['./result-edit-form-hj.component.scss'],
    imports: [
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatSelect,
        MatOption,
        MatButton,
        MatIcon,
        MatError,
        TranslocoDirective,
    ]
})
export class ResultEditFormHjComponent {
  private fb = inject(FormBuilder);
  private formService = inject(ResultEditFormService);

  @Input() form!: FormGroup;
  @Input() result: Results | undefined;
  @Input() set highJumpData(highJumpData: { height: string | number; data: any[]; trials: string[] }[]) {
    this._highJumpData = highJumpData;
    for (const data of highJumpData) {
      this.highJumpHeights.push(data.height);
    }
  }
  @Input() resultPrecision: number | undefined;
  @Input() role: Roles | undefined | null;
  @Input() isFromCombinedEvent = false;

  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() resultForm = new EventEmitter<Results>();

  roles = Roles;
  resultCodes = HJ_RESULT_ALTERNATIVE_CODE;
  _highJumpData: { height: number | string; data: any[]; trials: string[] }[] | undefined;
  highJumpHeights = [] as (number | string)[];
  highJumpValues = ['', '-', 'X', 0];
  newHeightInput = this.setNewHeightForm();

  //TODO: add validation for high jump
  validationStatus = [];

  setNewHeightForm() {
    return this.fb.group({
      height: new FormControl('', [
        highJumpHeightValidator(this.formService.highJumpHeightRegex),
        hightJumpNoDuplicateValidator(this.highJumpHeights),
      ]),
    });
  }

  addHighJumpHeight() {
    if (this.form && this._highJumpData) {
      const newHeight = this.newHeightInput.value.height;
      if (newHeight) {
        this.form.addControl(
          'height' + newHeight,
          new FormControl(newHeight, [
            highJumpHeightValidator(this.formService.highJumpHeightRegex),
            hightJumpNoDuplicateValidator(this.highJumpHeights, newHeight),
          ]),
        );
        for (let i = 0; i < 3; i++) {
          this.form.addControl(`value${newHeight}.${i}`, new FormControl(''));
        }
        this.newHeightInput.setControl(
          'height',
          new FormControl('', [
            highJumpHeightValidator(this.formService.highJumpHeightRegex),
            hightJumpNoDuplicateValidator(this.highJumpHeights),
          ]),
        );
        this.highJumpHeights.push(newHeight);
        this._highJumpData.push({
          height: newHeight,
          data: [{}, {}, {}],
          trials: ['', '', ''],
        });
      }
    }
  }

  removeHighJumpHeight(result: { height: any; data?: any[]; trials?: string[] }) {
    if (this.form && this._highJumpData) {
      const index = this._highJumpData.findIndex((data) => data.height === result.height);
      this._highJumpData.splice(index, 1);
      this.form.removeControl('height' + result.height);
      for (let i = 0; i < 3; i++) {
        this.form.removeControl(`value${result.height}.${i}`);
      }
    }
  }

  emitCloseDialog() {
    this.closeDialog.emit();
  }

  emitSaveResult() {
    if (this.form && this.result && this._highJumpData) {
      const form = { ...this.form.value };
      const newResult = JSON.parse(JSON.stringify(this.result));
      if (form['code']) {
        newResult.result.alternative_code = form['code'];
      }
      if (this.isFromCombinedEvent) {
        newResult.validation = form['validation'];
      }
      newResult.comment = form['comment'];
      newResult.result.currentOrder = form['position'];
      newResult.result.results = [];
      for (let i = 0; i < this._highJumpData.length; i++) {
        for (let j = 0; j < this._highJumpData[i].trials.length; j++) {
          let metaValue = form['height' + this._highJumpData[i].height];
          if (typeof metaValue == 'string' && metaValue.indexOf(',') > -1) {
            metaValue = form['height' + this._highJumpData[i].height].replace(',', '.');
          }
          newResult.result.results[i * 3 + j] = {
            ...this._highJumpData[i].data[j],
            metavalue: +metaValue,
            value: form[`value${this._highJumpData[i].height}.${j}`],
            seqno: this._highJumpData[i].data[j]?.seqno || j + 1,
          };
        }
      }
      newResult.result.results.sort((a: { metavalue: number }, b: { metavalue: number }) => a.metavalue - b.metavalue);
      this.resultForm.emit(newResult);
    }
  }
}
