<section>
  <h3>Modifier le résultat</h3>
  <hr />
  <div class="form-header">
    <h4 class="discipline-info">
      {{ result.name }} -
      @if (result.athleteCategory || result.eventCategory; as category) {
        {{ category.name }}
      }
    </h4>
    @if (result.type === resultType.Relay) {
      <h4 class="team-name">
        {{ result.result.teamName }}
      </h4>
    }
    @for (athlete of result?.athletes || result.rawAthletes; track athlete) {
      <h4
        class="athlete-names"
        >
        @if (result.type === resultType.Relay) {
          {{ athlete.order }}.
          }{{ athlete.firstname }} {{ athlete.lastname }}
        </h4>
      }
    </div>

    @if (formType === 'basic') {
      <beathletics-result-edit-form-basic
        [form]="form"
        [result]="result"
        [showWind]="showWind"
        [resultPrecision]="resultPrecision"
        [role]="$any(role$ | async)"
        (closeDialog)="closeDialog()"
        (resultForm)="saveResult($event)"
      ></beathletics-result-edit-form-basic>
    }

    @if (formType === 'high-jump') {
      <beathletics-result-edit-form-hj
        [highJumpData]="highJumpData"
        [form]="form"
        [result]="result"
        [resultPrecision]="resultPrecision"
        [role]="role$ | async"
        (closeDialog)="closeDialog()"
        (resultForm)="saveResult($event)"
      ></beathletics-result-edit-form-hj>
    }

    @if (formType === 'combined') {
      <beathletics-result-edit-form-combined
        [result]="result"
        [role]="role$ | async"
        (closeDialog)="closeDialog()"
        (resultForm)="saveResult($event)"
      ></beathletics-result-edit-form-combined>
    }
  </section>
