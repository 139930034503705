<form *transloco="let t" [formGroup]="form" class="flex flex-col px-[15px]">
  <!-- * GENERALITIES * -->

  <div class="flex justify-between">
    <mat-form-field class="w-[150px]">
      <mat-label>{{ t('BASIC_POSITION') }}</mat-label>
      <input matInput type="number" step="1" formControlName="rank" />
    </mat-form-field>

    <mat-form-field class="w-[150px]">
      <mat-label>{{ t('BASIC_ALTERNATIVE_CODE') }}</mat-label>
      <mat-select formControlName="feature" (selectionChange)="handleAltCode($event)">
        <mat-option [value]="null"></mat-option>
        @for (feat of features; track feat) {
          <mat-option [value]="feat">
            {{ feat }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div class="flex justify-between">
    @if (form.get('points')) {
      <mat-form-field class="w-[150px]">
        <mat-label>{{ t('BASIC_POINTS') }}</mat-label>
        <input matInput type="number" step="1" formControlName="points" required />
      </mat-form-field>
    }
    @if (form.get('heatNumber')) {
      <mat-form-field class="w-[150px]">
        <mat-label>{{ t('BASIC_HEAT') }}</mat-label>
        <input matInput type="number" step="1" formControlName="heatNumber" required />
      </mat-form-field>
    }
  </div>

  @if (form.get('startingOrder')) {
    <mat-form-field>
      <mat-label>{{ t('BASIC_STARTING_ORDER_OR_LANE') }}</mat-label>
      <input matInput type="number" step="1" formControlName="startingOrder" required />
    </mat-form-field>
  }

  <!-- * RESULTS / TRIALS * -->

  @if (type !== formType.HIGH_JUMP) {
    @if (trials && trials.length > 0) {
      @for (trial of trials; track trial; let i = $index) {
        <div class="mb-[15px]">
          <div class="flex justify-between items-center">
            <h4 class="font-bold mb-[15px]">
              {{ t('BASIC_RESULT') }}
              @if (trials.length > 1) {
                &nbsp;{{ i + 1 }}
              }
            </h4>
            @if (type !== formType.RACE && type !== formType.COMBINED_TOTAL) {
              <button mat-icon-button color="warn" [disabled]="trials.length <= 1" (click)="removeTrial(i)">
                <mat-icon>delete</mat-icon>
              </button>
            }
          </div>
          <mat-form-field class="w-full mb-[10px]">
            <mat-label>{{ t('BASIC_RESULT_VALUE') }}</mat-label>
            <input matInput formControlName="perf{{ i }}" />
            @if (usedForNewResult && type === formType.RACE) {
              <mat-hint>{{ t('RESULT_HINT_TIME_IN_SECONDS') }}</mat-hint>
            }
            @if (form.get('perf' + i)?.errors?.['required']) {
              <mat-error>* {{ t('ERROR_MANDATORY_FIELD') }}</mat-error>
            }
            @if (form.get('perf' + i)?.errors?.['resultPrecision']) {
              <mat-error>* {{ t('ERROR_RESULT_PRECISION', { precision }) }}</mat-error>
            }
          </mat-form-field>
          <!-- <beathletics-time-result-input></beathletics-time-result-input> -->
          @if (type === formType.COMBINED_TOTAL && !usedForNewResult) {
            <div class="text-center text-sm text-slate-500 mb-[25px]">
              <em [innerHTML]="t('RESULT_POINTS_CANNOT_BE_MODIFIED_MSG')"></em>
            </div>
          }
          @if (form.get('wind' + i)) {
            <mat-form-field class="w-full mb-[10px]">
              <mat-label>Vent</mat-label>
              <input matInput formControlName="wind{{ i }}" required />
              @if (form.get('wind' + i)?.errors?.['required']) {
                <mat-error>* {{ t('ERROR_MANDATORY_FIELD') }}</mat-error>
              }
            </mat-form-field>
          }
        </div>
      }
    }
  } @else {
    @if (hjTrials && hjTrials.length > 0) {
      @for (trial of hjTrials; track trial; let i = $index) {
        <div class="flex items-center justify-between">
          <mat-form-field class="max-w-[80px]">
            <mat-label>{{ t('BASIC_HEIGHT') }}</mat-label>
            <input matInput required formControlName="height{{ trial.height }}" />
          </mat-form-field>
          @for (n of [0, 1, 2]; track n; let j = $index) {
            <mat-form-field appearance="outline" class="max-w-[60px]">
              <mat-label>{{ t('BASIC_TRIAL') }} {{ j + 1 }}</mat-label>
              <mat-select formControlName="value{{ trial.height }}.{{ j }}">
                @for (value of highJumpValues; track value) {
                  <mat-option [value]="value">
                    {{ value }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          <button mat-icon-button color="warn" (click)="removeTrial(i, trial.height)">
            <mat-icon class="mb-[15px]">delete</mat-icon>
          </button>
        </div>
        <div class="mt-[-15px] mb-[10px] text-sm">
          @if (form.controls['height' + trial.height].errors?.['required'] && form.controls['feature'].value === null) {
            <mat-error>* {{ t('ERROR_MANDATORY_WITHOUT_CODE') }}</mat-error>
          }
          @if (form.controls['height' + trial.height].errors?.['heightPrecision']) {
            <mat-error>* {{ t('ERROR_RESULT_PRECISION', { precision }) }}</mat-error>
          }
          @if (form.controls['height' + trial.height].errors?.['noDuplicate']) {
            <mat-error>* {{ t('ERROR_VALUE_ALREADY_IN_LIST') }}</mat-error>
          }
        </div>
      }
    }
  }
  @if (type !== formType.HIGH_JUMP) {
    @if (type !== formType.RACE && type !== formType.COMBINED_TOTAL) {
      <button mat-button color="primary" class="!mb-[15px]" (click)="addNewTrial()">
        <mat-icon>add</mat-icon>{{ t('RESULT_ADD_RESULT') }}
      </button>
    }
  } @else {
    <form [formGroup]="newHeightInput" class="flex items-center">
      <mat-form-field class="max-w-[80px]">
        <mat-label>Hauteur</mat-label>
        <input matInput formControlName="height" />
      </mat-form-field>
      <button
        mat-button
        color="primary"
        class="w-fit ml-[25px]"
        [disabled]="newHeightInput.get('height')?.invalid"
        (click)="addNewTrial()"
      >
        <mat-icon>add</mat-icon>{{ t('RESULT_ADD_HEIGHT') }}
      </button>
    </form>
  }

  <!-- * COMMENT & WARNINGS * -->

  <mat-form-field appearance="outline">
    <mat-label>{{ t('RESULT_EXPLAIN_MODIFICATION') }}</mat-label>
    <input matInput formControlName="comment" required />
    @if (form.get('comment')?.errors?.['required']) {
      <mat-error>* {{ t('ERROR_MANDATORY_FIELD') }}</mat-error>
    }
    @if (form.get('comment')?.errors?.['minlength']) {
      <mat-error>* {{ t('ERROR_EXPLAIN_MODIFICATION') }}</mat-error>
    }
  </mat-form-field>
</form>
