import { Component, EventEmitter, Input, Output, computed, effect, inject, signal } from '@angular/core';
import { IPrimeTreeParent, IPrimeTreeChild, ShopDiscipline } from '@beathletics/api-interfaces';
import { TranslocoService, TranslocoDirective } from '@jsverse/transloco';
import { BeathleticsUiModule } from '@beathletics/beathletics-ui';

interface IDisciplineTreeParent extends IPrimeTreeParent<ShopDiscipline, string> {
  label: string;
  data: string;
  children: IDisciplineTreeChild[];
}

interface IDisciplineTreeChild extends IPrimeTreeChild<ShopDiscipline, string> {
  label: string;
  type: 'node';
  key: string;
  data: ShopDiscipline;
}

@Component({
    selector: 'beathletics-discipline-by-group-selector',
    templateUrl: './discipline-by-group-selector.component.html',
    imports: [TranslocoDirective, BeathleticsUiModule]
})
export class DisciplineByGroupSelectorComponent {
  transloco = inject(TranslocoService);

  @Input() appendTo: 'body' | 'self' = 'body';
  @Input() set data(
    data:
      | {
          [key: string]: {
            name: string;
            disciplines: ShopDiscipline[];
          };
        }
      | undefined,
  ) {
    if (data) {
      this.disciplinesByGroup = Object.values(data).map((value) => ({
        label: this.transloco.translate('DISCIPLINE_GROUP_' + value.name),
        selectable: true,
        data: value.name,
        parent: true,
        children: value.disciplines.map((discipline) => ({
          label: discipline.name,
          data: {
            ...discipline,
            catList: discipline.categories?.map((c) => c.name).join(', ') || '',
          },
          key: discipline.shopReference,
          type: 'node',
        })),
      }));
    }
  }
  @Input() set selectedDisciplineRefs(shopReferences: string[] | undefined) {
    if (shopReferences && this.disciplinesByGroup) {
      const nodes = this.disciplinesByGroup
        .map((group) => {
          let keepFullGroup = true;
          for (const discipline of group.children) {
            if (!shopReferences.includes(discipline.data.shopReference)) {
              keepFullGroup = false;
              break;
            }
          }
          if (keepFullGroup) {
            return [group, ...group.children];
          }
          return group.children;
        })
        .flat()
        .filter((discipline) => {
          if (typeof discipline.data !== 'string') {
            return shopReferences.includes(discipline.data.shopReference);
          }
          return true;
        });
      this.selectedDisciplines.set(nodes);
    }
  }

  @Output() disciplines = new EventEmitter<ShopDiscipline[]>();

  disciplinesByGroup: IDisciplineTreeParent[] | null = null;
  selectedDisciplines = signal<(IDisciplineTreeParent | IDisciplineTreeChild)[]>([]);

  _selectedDisciplineRefs = computed(() => {
    return this.selectedDisciplines().map((discipline) =>
      typeof discipline.data !== 'string' ? discipline.data.shopReference : discipline.data,
    );
  });

  propagateDisciplinesChanges = effect(() => {
    this.disciplines.emit(
      this.selectedDisciplines()
        .filter((discipline) => typeof discipline.data !== 'string')
        .map((discipline) => discipline.data as ShopDiscipline),
    );
  });

  selectDisciplines($event: (ShopDiscipline | string)[]) {
    this.disciplines.emit($event.filter((discipline) => typeof discipline !== 'string') as ShopDiscipline[]);
  }
}
