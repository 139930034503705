import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatButtonToggle, MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatRadioButton, MatRadioChange, MatRadioGroup } from '@angular/material/radio';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { DisciplineGroups } from '@beathletics/api-interfaces';
import { NewCompetitionService, SharedDataService } from '@beathletics/beathletics-data-state';
import type { EventType } from '@prisma/client';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'beathletics-event-type-selection-dialog',
    templateUrl: './event-type-selection-dialog.component.html',
    imports: [
        MatButtonToggleGroup,
        ReactiveFormsModule,
        FormsModule,
        MatButtonToggle,
        MatRadioGroup,
        MatRadioButton,
        MatFormField,
        MatLabel,
        MatSelect,
        MatOption,
        MatProgressSpinner,
        MatButton,
        MatDialogClose,
        AsyncPipe,
    ]
})
export class EventTypeSelectionDialogComponent {
  public data = inject(MAT_DIALOG_DATA) as { disciplineId: string; parentId?: string };
  private dialogRef = inject(MatDialogRef<EventTypeSelectionDialogComponent>);
  private sharedData = inject(SharedDataService);
  private competitionService = inject(NewCompetitionService);

  eventTypes$ = this.sharedData.eventTypes$;
  disciplineGroups = Object.values(DisciplineGroups);
  venueType?: string;
  disciplineType?: DisciplineGroups;
  filteredEventTypes$?: Observable<EventType[]>;
  selectedEventType?: EventType;

  filterEventTypesByVenue(change: MatButtonToggleChange) {
    this.filteredEventTypes$ = this.eventTypes$.pipe(
      map((eventTypes) => {
        const filteredData = eventTypes.filter(
          (eventType) =>
            eventType.venue === change.value &&
            (this.disciplineType ? eventType.discipline_group === this.disciplineType : true),
        );
        return this.sortEventTypes(filteredData);
      }),
    );
  }

  filterEventTypesByGroup(change: MatRadioChange) {
    const group = change.value as DisciplineGroups;
    this.filteredEventTypes$ = this.eventTypes$.pipe(
      map((eventTypes) => {
        const filteredData = eventTypes.filter(
          (eventType) => eventType.discipline_group === group && eventType.venue === this.venueType,
        );
        return this.sortEventTypes(filteredData);
      }),
    );
  }

  sortEventTypes(eventTypes: EventType[]) {
    return eventTypes
      .sort((a, b) => +b.type_id - +a.type_id)
      .sort((a, b) => ((a.name_fr ?? '') > (b.name_fr ?? '') ? 1 : -1))
      .sort((a, b) => a.distance - b.distance);
  }

  selectEventType(change: MatSelectChange) {
    const eventType = change.value as EventType;
    this.selectedEventType = eventType;
  }

  validateEventType() {
    if (this.selectedEventType) {
      if (this.data?.disciplineId) {
        this.competitionService.connectEventTypeWithDiscipline(
          this.data.disciplineId,
          this.selectedEventType.id,
          this.data.parentId,
        );
        this.dialogRef.close();
      } else {
        this.dialogRef.close(this.selectedEventType);
      }
    }
  }
}
