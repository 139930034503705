import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatList, MatListItem } from '@angular/material/list';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EProvincialCommittee, KeycloakRole, KeycloakUser, Roles } from '@beathletics/api-interfaces';
import { AuthService } from '@beathletics/auth';
import { SharedDataService } from '@beathletics/beathletics-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '../../../../shared/services/dialog.service';

@UntilDestroy()
@Component({
  selector: 'beathletics-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss'],
  imports: [
    AsyncPipe,
    CdkScrollable,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    MatButton,
    MatList,
    MatListItem,
    MatIconButton,
    MatIcon,
    FormsModule,
    ReactiveFormsModule,
    UpperCasePipe,
  ],
})
export class UserEditDialogComponent {
  public data = inject(MAT_DIALOG_DATA) as KeycloakUser;
  private dialogRef = inject(MatDialogRef<UserEditDialogComponent>);
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private dialogService = inject(DialogService);
  private snackbar = inject(MatSnackBar);
  private sharedService = inject(SharedDataService);

  clubs$ = this.sharedService.clubs$;
  roles = Roles;
  availableRoles: KeycloakRole[] | undefined;
  userRoles: KeycloakRole[] | undefined;
  allRoles = this.authService
    .getAllRealmRoles()
    .pipe(untilDestroyed(this))
    .subscribe((roles) => {
      this.availableRoles = roles.filter((role: KeycloakRole) => !this.data.roles?.includes(role.name));
      this.userRoles = roles.filter((role: KeycloakRole) => this.data.roles?.includes(role.name));
    });
  editUserForm: FormGroup = this.setForm();
  selectedRole: KeycloakRole | undefined;
  mailLinkLifespan: number | undefined;
  provinces = Object.values(EProvincialCommittee);

  setForm() {
    return this.fb.group({
      firstName: [this.data.firstName],
      lastName: [this.data.lastName],
      email: [this.data.email],
      liveId: [this.data.attributes?.liveId?.[0]],
      club: [this.data.attributes?.club?.[0]],
      province: [this.data.attributes?.province?.[0]],
    });
  }

  closeDialog(reload = false) {
    this.dialogRef.close(reload);
  }

  setUserData() {
    const newUserData = {
      ...this.data,
      firstName: this.editUserForm.value.firstName,
      lastName: this.editUserForm.value.lastName,
      email: this.editUserForm.value.email,
      attributes: {
        liveId: this.editUserForm.value.liveId,
        club: this.editUserForm.value.club,
        province: this.editUserForm.value.province,
      },
    } as KeycloakUser;
    delete newUserData.roles;
    return newUserData;
  }

  updateUser() {
    const newUserData = this.setUserData();
    this.authService
      .updateUserData(newUserData)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: (err) => {
          this.closeDialog();
          this.dialogService.openNotificationDialog('Une erreur est survenue', [
            `Error ${err.error.statusCode} : ${err.error.message}`,
          ]);
        },
        complete: () => this.closeDialog(true),
      });
  }

  async addRole() {
    this.data.id &&
      this.selectedRole &&
      this.authService
        .addRoleToUserId(this.data.id, this.selectedRole)
        .pipe(untilDestroyed(this))
        .subscribe({
          error: (err) => {
            this.closeDialog();
            this.dialogService.openNotificationDialog('Une erreur est survenue', [
              `Error ${err.error.statusCode} : ${err.error.message}`,
            ]);
          },
          complete: () => this.closeDialog(true),
        });
  }

  removeRole(role: KeycloakRole) {
    this.data.id &&
      this.authService
        .removeRoleByUserId(this.data.id, role)
        .pipe(untilDestroyed(this))
        .subscribe({
          error: (err) => {
            this.closeDialog();
            this.dialogService.openNotificationDialog('Une erreur est survenue', [
              `Error ${err.error.statusCode} : ${err.error.message}`,
            ]);
          },
          complete: () => this.closeDialog(true),
        });
  }

  sendPasswordResetMail() {
    this.data.id &&
      this.mailLinkLifespan &&
      this.authService
        .sendActionEmail(this.data.id, 'UPDATE_PASSWORD', this.mailLinkLifespan * 60 * 60)
        .pipe(untilDestroyed(this))
        .subscribe({
          error: (err) => {
            this.closeDialog();
            this.dialogService.openNotificationDialog('Une erreur est survenue', [
              `Error ${err.error.statusCode} : ${err.error.message}`,
            ]);
          },
          complete: () => {
            this.closeDialog(true);
            this.snackbar.open('Email correctement envoyé !', 'X');
          },
        });
  }
}
