@if (role) {
  <form [formGroup]="form" class="edit-result-form">
    @if (role !== roles.Homologation) {
      <div class="flex-between">
        <mat-form-field class="position-field">
          <mat-label>Position</mat-label>
          <input matInput formControlName="position" />
        </mat-form-field>
        @if (isFromCombinedEvent) {
          <mat-form-field class="points-field">
            <mat-label>Points</mat-label>
            <input matInput formControlName="points" />
          </mat-form-field>
        }
        <mat-form-field class="alternative-code">
          <mat-label>Code alternatif</mat-label>
          <mat-select formControlName="code">
            <mat-option [value]="null"></mat-option>
            @for (code of resultCodes; track code) {
              <mat-option [value]="code">
                {{ code }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      @for (result of _highJumpData; track result) {
        <div>
          <div class="hj-result-container">
            <mat-form-field class="height-field">
              <mat-label>Hauteur</mat-label>
              <input matInput required formControlName="height{{ result.height }}" />
            </mat-form-field>
            @for (trial of result.trials; track trial; let i = $index) {
              <mat-form-field appearance="outline" class="hj-result-field">
                <mat-label>Essai {{ i + 1 }}</mat-label>
                <mat-select formControlName="value{{ result.height }}.{{ i }}">
                  @for (value of highJumpValues; track value) {
                    <mat-option [value]="value">
                      {{ value }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            }
            <button mat-button color="warn" (click)="removeHighJumpHeight(result)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div class="field-errors">
            @if (form.controls['height' + result.height].errors?.['required'] && form.controls['code'].value === null) {
              <mat-error>* Champs est obligatoire si aucun code sélectionné</mat-error>
            }
            @if (form.controls['height' + result.height].errors?.['heightPrecision']) {
              <mat-error>* Ce type de résultat doit avoir {{ resultPrecision }} chiffre(s) après la virgule</mat-error>
            }
            @if (form.controls['height' + result.height].errors?.['noDuplicate']) {
              <mat-error>* Cette valeur est déjà dans la liste</mat-error>
            }
          </div>
        </div>
      }
      <form [formGroup]="newHeightInput" class="new-height-form">
        <mat-form-field class="height-field">
          <mat-label>Hauteur</mat-label>
          <input matInput formControlName="height" />
        </mat-form-field>
        <button
          mat-button
          color="primary"
          class="add-height-btn"
          [disabled]="newHeightInput.get('height')?.invalid"
          (click)="addHighJumpHeight()"
        >
          <mat-icon>add</mat-icon>Ajouter une hauteur
        </button>
      </form>
      <div class="field-errors smaller">
        @if (newHeightInput.controls.height.errors?.['heightPrecision']) {
          <mat-error>* Ce type de résultat doit avoir {{ resultPrecision }} chiffre(s) après la virgule</mat-error>
        }
        @if (newHeightInput.controls.height.errors?.['noDuplicate']) {
          <mat-error>* Cette hauteur est déjà dans la liste</mat-error>
        }
      </div>
    }
    @if (isFromCombinedEvent) {
      <mat-form-field *transloco="let t">
        <mat-label>Validation</mat-label>
        <mat-select formControlName="validation">
          @for (status of validationStatus; track status) {
            <mat-option [value]="status">
              {{ t('STATUS_' + status) }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    <div class="dialog-bottom">
      <mat-form-field class="comment-field" appearance="outline">
        <mat-label>Expliquez la raison de votre modification</mat-label>
        <input matInput formControlName="comment" />
        @if (form.get('comment')?.errors?.['required']) {
          <mat-error>* Ce champs est obligatoire</mat-error>
        }
        @if (form.get('comment')?.errors?.['minlength']) {
          <mat-error>* Précisez la raison de la modification (min. 10 car.)</mat-error>
        }
      </mat-form-field>
      @if (isFromCombinedEvent) {
        @if (form.get('points')?.dirty) {
          <div class="points-warning">
            <span>Les points ont été modifiés, l'enregistrement impactera le total.</span>
          </div>
        }
      }
      <div class="btn-container">
        <button mat-raised-button color="warn" (click)="emitCloseDialog()">Annuler</button>
        <button
          mat-raised-button
          color="primary"
          (click)="emitSaveResult()"
          [disabled]="
            !form.dirty || (form.invalid && (form.controls['code'].value === null || form.get('comment')?.invalid))
          "
        >
          Enregistrer
        </button>
      </div>
    </div>
  </form>
}
