<div *transloco="let t">
  <div class="mb-4 flex flex-col">
    <label class="mb-4 text-center">
      @if ((nbAthletes || 1) > 1) {
        {{ t('SELECT_ATHLETES') }} ({{
          selectedAthletes.length + '/' + nbAthletes
        }})
      } @else {
        {{ t('SELECT_ATHLETE') }}
      }
    </label>
    @if (!isFromCombinedEvents) {
      <div class="flex justify-between">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>{{ t('BASIC_STARTING_ORDER_OR_LANE') }}</mat-label>
          <input matInput type="number" [(ngModel)]="startingOrder" required />
        </mat-form-field>
        @if ((nbAthletes || 1) > 1) {
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>{{ t('BASIC_RELAY_TEAM_NAME') }}</mat-label>
            <input matInput [(ngModel)]="teamName" required />
          </mat-form-field>
        }
      </div>
    }
  </div>

  <beathletics-athlete-search-bar
    action="RETURN"
    [searchAutocomplete]="$any(searchAutocomplete$ | async) || []"
    [removePadding]="true"
    [loading]="loading()"
    [error]="error()"
    [disabled]="selectedAthletes.length === nbAthletes"
    (searchValue)="searchValue.next($event)"
    (athleteToReturn)="setAthlete($event)"
  ></beathletics-athlete-search-bar>

  @for (athlete of selectedAthletes; track athlete) {
    <div class="flex flex-col">
      <div class="flex justify-center items-center my-4">
        <span>
          {{ (nbAthletes || 1) > 1 ? $index + 1 + '.' : '' }}
          {{ athlete.person.lastName }},
          {{ athlete.person.firstName }}
        </span>
        <button mat-icon-button (click)="removeAthlete(athlete.id)">
          <mat-icon color="warn">delete</mat-icon>
        </button>
      </div>
      <div class="flex justify-between items-center">
        <mat-form-field
          appearance="outline"
          class="max-w-28"
          subscriptSizing="dynamic"
        >
          <mat-label>{{ t('BASIC_BIB') }}</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="athletesBibs[$index + 1]"
            required
          />
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="max-w-32"
          subscriptSizing="dynamic"
        >
          <mat-label>{{ t('BASIC_CATEGORY') }}</mat-label>
          <mat-select [(ngModel)]="athletesCategories[$index + 1]" required>
            @for (category of categories$ | async; track category) {
              <mat-option [value]="category.abbr">{{
                category.abbr
              }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="max-w-28"
          subscriptSizing="dynamic"
        >
          <mat-label>{{ t('BASIC_CLUB') }}</mat-label>
          <mat-select [(ngModel)]="athletesClubs[$index + 1]">
            @for (club of clubs$ | async; track club) {
              <mat-option [value]="club.id">{{ club.abbr }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  }

  <div class="flex justify-between mt-8 mb-4">
    <button mat-raised-button mat-dialog-close color="warn">
      {{ t('BASIC_CANCEL') }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="
        selectedAthletes.length < (nbAthletes || 1) ||
        (teamName === '' && (nbAthletes || 1) > 1) ||
        (!startingOrder && !isFromCombinedEvents)
      "
      (click)="validateAthletes()"
    >
      {{ t('BASIC_CONFIRM') }}
    </button>
  </div>
</div>
