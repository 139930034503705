import { Component, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisciplineGroups, DisciplineImportType, Roles, ResultFormTypes } from '@beathletics/api-interfaces';
import { Store } from '@ngxs/store';
import { UpdateResult } from '@beathletics/beathletics-data-state';
import { ResultEditFormService } from '../../../shared/result-edit-form.service';
import { AuthService } from '@beathletics/auth';
import type { Results } from '@prisma/client';
import { ResultEditFormBasicComponent } from '../result-edit-form-basic/result-edit-form-basic.component';
import { ResultEditFormHjComponent } from '../result-edit-form-hj/result-edit-form-hj.component';
import { ResultEditFormCombinedComponent } from '../result-edit-form-combined/result-edit-form-combined.component';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'beathletics-result-edit-dialog',
    templateUrl: './result-edit-dialog.component.html',
    styleUrls: ['./result-edit-dialog.component.scss'],
    imports: [ResultEditFormBasicComponent, ResultEditFormHjComponent, ResultEditFormCombinedComponent, AsyncPipe]
})
export class ResultEditDialogComponent {
  result = inject(MAT_DIALOG_DATA);
  private dialogRef = inject<MatDialogRef<ResultEditDialogComponent>>(MatDialogRef);
  private store = inject(Store);
  private authService = inject(AuthService);
  private formService = inject(ResultEditFormService);

  resultType = DisciplineImportType;
  roles = Roles;
  formTypes = ResultFormTypes;
  resultPrecision: number | undefined;
  showWind: boolean | undefined;
  highJumpData!: any[];

  role$ = this.authService.getHigherRole();
  formType = this.selectForm();
  form!: FormGroup;

  selectForm() {
    const eventType = this.result?.eventType || this.result?.resultEventType;

    this.resultPrecision =
      ('' + eventType?.precision).length - 1 || ('' + eventType?.resultType?.precision).length - 1 || 99;

    const windMode = eventType?.wind_mode || eventType?.windmode;
    this.showWind = windMode !== 'N';

    if (this.result?.xmlId) {
      if (this.result.type !== DisciplineImportType.Combined) {
        const group = eventType?.discipline_group || eventType?.resultType?.group;
        if (
          group !== DisciplineGroups.Saut ||
          eventType?.shortcode === 'LJ' ||
          eventType?.abbr === 'LJ' ||
          eventType?.shortcode === 'TJ' ||
          eventType?.abbr === 'TJ'
        ) {
          this.form = this.formService.setBasicEditForm(this.result, this.resultPrecision, this.showWind);
          return 'basic';
        } else {
          const data = this.formService.setHighJumpEditForm(this.result, this.resultPrecision);
          this.highJumpData = data.highJumpResults;
          this.form = data.form;
          return 'high-jump';
        }
      } else {
        return 'combined';
      }
    } else {
      this.form = this.formService.setBasicEditForm(this.result, this.resultPrecision, this.showWind);
      return 'basic';
    }
  }

  closeDialog(result?: Results) {
    this.dialogRef.close(result);
  }

  saveResult(newResult: Results) {
    this.store.dispatch(new UpdateResult(newResult)).subscribe({
      complete: () => {
        this.closeDialog(newResult);
      },
      error: (err) => {
        console.error('Error Saving Result', err);
      },
    });
  }
}
