import { DatePipe, JsonPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';
import { TypedDialog } from '@beathletics/beathletics-ui';
import { TranslocoDirective } from '@jsverse/transloco';
import type { BusinessLog } from '@prisma/client';

@Component({
  selector: 'beathletics-log-details',
  template: `
    <section *transloco="let t" class="max-w-[40rem] flex flex-col">
      <div class="text-center mb-4">
        <h3 class="text-xl whitespace-nowrap overflow-hidden text-ellipsis">{{ data.log.comment }}</h3>
        <h4 class="text-base text-gray-500 mt-1">{{ data.log.user }}, {{ data.log.date | date: 'dd/MM/y hh:mm' }}</h4>
      </div>

      <div class="max-h-[40rem] overflow-y-auto bg-slate-100 p-2">
        <pre>{{ data.log.data | json }}</pre>
      </div>

      <div class="flex justify-center mt-4">
        <button mat-raised-button [mat-dialog-close]="null">{{ t('BASIC_CLOSE') }}</button>
      </div>
    </section>
  `,
  imports: [DatePipe, JsonPipe, MatButton, MatDialogClose, TranslocoDirective],
})
export class LogDetailsComponent extends TypedDialog<{ log: BusinessLog }, void> {}
