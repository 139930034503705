<section>
  <h3>Edit Event {{ event.eventNumber }}</h3>

  <form [formGroup]="eventEditForm">

    <div>
      <h4>Description</h4>
      <div>
        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Année</mat-label>
          <input type="number" matInput formControlName="year" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Facility</mat-label>
          <input matInput formControlName="facility" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Heure</mat-label>
          <input type="time" matInput formControlName="time" />
        </mat-form-field>
      </div>
    </div>

    <div>
      <h4>Dates</h4>
      <div>
        <h5>Début</h5>
        <mat-form-field>
          <mat-label>Jour</mat-label>
          <input type="number" matInput formControlName="start_day" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Mois</mat-label>
          <input type="number" matInput formControlName="start_month" />
        </mat-form-field>
      </div>
      <div>
        <h5>Fin</h5>
        <mat-form-field>
          <mat-label>Jour</mat-label>
          <input type="number" matInput formControlName="end_day" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Mois</mat-label>
          <input type="number" matInput formControlName="end_month" />
        </mat-form-field>
      </div>
    </div>

    <div style="display: flex; justify-content: space-evenly;">
      <button mat-raised-button color="warn" (click)="deleteEvent()">Supprimer</button>
      <button mat-raised-button color="primary" (click)="saveEvent()">Enregistrer</button>
      <button mat-raised-button color="basic" (click)="closeDialog()">Annuler</button>
    </div>
  </form>
</section>
