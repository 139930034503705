<div *transloco="let t" class="max-w-md">
  @if (showList) {
    @if (orderData?.status === orderStatus.VALIDATED || orderData?.status === orderStatus.CANCELLED) {
      <div class="text-center pt-2">
        <em>{{ t('REGISTRATION_ORDER_ALREADY_' + orderData?.status) }}</em>
      </div>
    }
    @for (athlete of order.athletes; track athlete) {
      <div class="my-2">
        <span class="flex items-center">
          <mat-checkbox
            color="primary"
            [(ngModel)]="athlete.checked"
            [indeterminate]="someChecked(athlete)"
            [disabled]="
              orderData?.status === orderStatus.VALIDATED ||
              orderData?.status === orderStatus.CANCELLED ||
              orderData?.status === orderStatus.SUBMITTED_AND_PAY
            "
            (change)="checkAllDisciplines(athlete, $event.checked)"
            ><strong [style.text-decoration]="athlete.status === itemStatus.REJECTED ? 'line-through' : 'none'">
              {{ athlete.name }} - {{ athlete.category }} ({{ athlete.club }})
            </strong>
            @if (athlete.status === itemStatus.REJECTED) {
              <em> &nbsp; ({{ t('BASIC_REFUSED') }}) </em>
            }</mat-checkbox
          >&nbsp;
          @if (
            orderData?.status !== orderStatus.VALIDATED &&
            orderData?.status !== orderStatus.CANCELLED &&
            orderData?.status !== orderStatus.SUBMITTED_AND_PAY
          ) {
            <button mat-icon-button color="warn" (click)="confirmDeletion(athlete)">
              <mat-icon class="mb-2">delete</mat-icon>
            </button>
          }
        </span>
        <ul class="ml-8">
          @for (discipline of athlete.disciplines; track discipline) {
            <li class="flex flex-col">
              <div class="flex items-center">
                <mat-checkbox
                  color="primary"
                  [(ngModel)]="discipline.checked"
                  [disabled]="
                    orderData?.status === orderStatus.VALIDATED ||
                    orderData?.status === orderStatus.CANCELLED ||
                    orderData?.status === orderStatus.SUBMITTED_AND_PAY
                  "
                  (ngModelChange)="verifyIfAllChecked(athlete)"
                  ><span [style.text-decoration]="discipline.status === itemStatus.REJECTED ? 'line-through' : 'none'"
                    >{{ discipline.name }}
                    @if (discipline.relayData) {
                      - {{ discipline.relayData.team }}
                    }
                  </span>
                  @if (discipline.status === itemStatus.REJECTED) {
                    <em> &nbsp; ({{ t('BASIC_REFUSED') }}) </em>
                  }</mat-checkbox
                >&nbsp;
                @if (
                  orderData?.status !== orderStatus.VALIDATED &&
                  orderData?.status !== orderStatus.CANCELLED &&
                  orderData?.status !== orderStatus.SUBMITTED_AND_PAY
                ) {
                  <button mat-icon-button color="warn" (click)="confirmDeletion(discipline)">
                    <mat-icon class="mb-2">close</mat-icon>
                  </button>
                }
              </div>
              @if (discipline.relayData) {
                <ol class="ml-10 mt-[-8px] text-xs text-gray-500 italic">
                  @for (athlete of discipline.relayData.athletes; track athlete) {
                    <li>
                      {{ athlete.order }}. {{ athlete.name }} ({{
                        athlete.club || athlete.federation || athlete.localTeam
                      }})
                    </li>
                  }
                </ol>
              }
            </li>
          }
        </ul>
      </div>
    }
    <hr />
    <div class="pt-6 flex justify-between">
      <button mat-raised-button color="primary" (click)="validateSelection()" [disabled]="disableBtn">
        {{ t('REGISTRATION_VALIDATE_SELECTION_REFUSE_REST') }}
      </button>
      <button mat-raised-button class="ml-4" (click)="closeDialog.emit()">
        {{ t('BASIC_CLOSE') }}
      </button>
    </div>
  } @else {
    @if (deleteItem) {
      <div class="text-center py-4">
        <em class="text-red-700">
          {{ t('REGISTRATION_ORDER_DELETION_WARNING') }}
        </em>
      </div>
      <hr />
      <div class="pt-6 flex justify-between">
        <button mat-raised-button color="warn" (click)="deleteOrderItem()">
          {{ t('BASIC_DELETE') }}
        </button>
        <button mat-raised-button (click)="deleteItem = false; showList = true">
          {{ t('BASIC_CANCEL') }}
        </button>
      </div>
    } @else {
      <div class="text-center py-4">
        @if (isAllSelected) {
          <em [innerHTML]="t('REGISTRATION_ORDER_VALIDATION_WARNING')"></em>
        } @else {
          <em [innerHTML]="t('REGISTRATION_ORDER_VALIDATION_MESSAGE')"></em>
        }
      </div>
      <hr />
      <div class="pt-6 flex justify-between">
        <button mat-raised-button color="primary" (click)="confirmValidation()">
          {{ t('BASIC_CONFIRM') }}
        </button>
        <button mat-raised-button (click)="showList = true">
          {{ t('BASIC_CANCEL') }}
        </button>
      </div>
    }
  }
</div>
