@if (role) {
  <form [formGroup]="form" class="edit-result-form">
    @if (role !== roles.Homologation) {
      <div [class.flex-pos-and-points]="isFromCombinedEvent">
        <mat-form-field>
          <mat-label>Position</mat-label>
          <input matInput formControlName="position" />
        </mat-form-field>
        @if (isFromCombinedEvent) {
          <mat-form-field>
            <mat-label>Points</mat-label>
            <input matInput type="number" formControlName="points" />
          </mat-form-field>
        }
      </div>
      @if ($any(_result?.result)?.results?.length > 0) {
        @for (result of $any(_result?.result).results; track result; let index = $index) {
          <div class="result-header">
            <h4 class="result-title">Résultat {{ index + 1 }}</h4>
            <button mat-button color="warn" (click)="removeResult(result)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <mat-form-field>
            <mat-label>Valeur du résultat</mat-label>
            <input matInput formControlName="value{{ index }}" />
            @if (form.get('value' + index)?.errors?.['required']) {
              <mat-error
                >* Ce champs est obligatoire</mat-error
                >
              }
              @if (form.get('value' + index)?.errors?.['resultPrecision']) {
                <mat-error
                  >* Ce type de résultat doit avoir {{ resultPrecision }} chiffre(s)
                  après la virgule</mat-error
                  >
                }
              </mat-form-field>
              @if (showWind) {
                <mat-form-field>
                  <mat-label>Vent</mat-label>
                  <input matInput formControlName="wind{{ index }}" />
                  @if (form.get('wind' + index)?.errors?.['required']) {
                    <mat-error
                      >* Ce champs est obligatoire</mat-error
                      >
                    }
                  </mat-form-field>
                }
              }
            } @else {
              <div class="result-header">
                <h4 class="result-title">Résultat 1</h4>
                <button mat-button color="warn" (click)="removeResult(result)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <mat-form-field>
                <mat-label>Valeur du résultat</mat-label>
                <input matInput formControlName="value0" />
                @if (form.get('value0')?.errors?.['required']) {
                  <mat-error
                    >* Ce champs est obligatoire</mat-error
                    >
                  }
                  @if (form.get('value0')?.errors?.['resultPrecision']) {
                    <mat-error
                      >* Ce type de résultat doit avoir {{ resultPrecision }} chiffre(s)
                      après la virgule</mat-error
                      >
                    }
                  </mat-form-field>
                  @if (showWind) {
                    <mat-form-field>
                      <mat-label>Vent</mat-label>
                      <input matInput formControlName="wind0" />
                      @if (form.get('wind0')?.errors?.['required']) {
                        <mat-error
                          >* Ce champs est obligatoire</mat-error
                          >
                        }
                      </mat-form-field>
                    }
                  }
                }
                <div class="add-result-container">
                  <button
                    mat-button
                    color="primary"
                    class="add-height-btn"
                    (click)="addNewResult()"
                    >
                    <mat-icon>add</mat-icon>Ajouter un résultat
                  </button>
                </div>
                @if (isFromCombinedEvent) {
                  <mat-form-field *transloco="let t">
                    <mat-label>Validation</mat-label>
                    <mat-select formControlName="validation">
                      @for (status of validationStatus; track status) {
                        <mat-option [value]="status">
                          {{ t('STATUS_' + status) }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                }
                <div class="dialog-bottom">
                  <mat-form-field class="comment-field" appearance="outline">
                    <mat-label>Expliquez la raison de votre modification</mat-label>
                    <input matInput formControlName="comment" />
                    @if (form.get('comment')?.errors?.['required']) {
                      <mat-error
                        >* Ce champs est obligatoire</mat-error
                        >
                      }
                      @if (form.get('comment')?.errors?.['minlength']) {
                        <mat-error
                          >* Précisez la raison de la modification (min. 10 car.)</mat-error
                          >
                        }
                      </mat-form-field>
                      @if (isFromCombinedEvent) {
                        @if (form.get('points')?.dirty) {
                          <div class="points-warning">
                            <span
                              >Les points ont été modifiés, l'enregistrement impactera le
                              total.</span
                              >
                            </div>
                          }
                        }
                        <div class="btn-container">
                          <button mat-raised-button color="warn" (click)="emitCloseDialog()">
                            Annuler
                          </button>
                          <button
                            mat-raised-button
                            color="primary"
                            (click)="emitSaveResult()"
                            [disabled]="!form.dirty || form.invalid"
                            >
                            Enregistrer
                          </button>
                        </div>
                      </div>
                    </form>
                  }
