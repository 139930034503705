import { Component, inject } from '@angular/core';
import { Roles } from '@beathletics/api-interfaces';
import { TypedDialog } from '@beathletics/beathletics-ui';
import { MatButton } from '@angular/material/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { EventSearchBarComponent } from '../event-search-bar/event-search-bar.component';
import { MatDialogClose, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'beathletics-search-competition-dialog',
  template: `
    <div *transloco="let t" class="flex flex-col w-[480px]">
      <div class="text-center text-xl font-medium mb-4">
        <h2>{{ t('SEARCH_COMPETITION') }} ({{ isAdmin ? 'ADMIN' : data.club || 'N.A.' }})</h2>
      </div>
      @if (data.club || isAdmin) {
        <div>
          <beathletics-event-search-bar
            [limitedToClub]="isAdmin ? undefined : data.club"
            (clicked)="dialogRef.close()"
          ></beathletics-event-search-bar>
        </div>
      } @else {
        <div class="text-center">
          <em [innerHTML]="t('SEARCH_COMPETITION_BLOCKED_MSG')"></em>
        </div>
      }
      <button
        [style.margin-top]="isAdmin || data.club ? '4px' : '16px'"
        color="primary"
        mat-raised-button
        mat-dialog-close
      >
        {{ t('BASIC_CLOSE') }}
      </button>
    </div>
  `,
  imports: [EventSearchBarComponent, MatButton, MatDialogClose, TranslocoDirective],
})
export class SearchCompetitionDialogComponent extends TypedDialog<{ roles: Roles[]; club?: string }, void> {
  override dialogRef = inject<MatDialogRef<SearchCompetitionDialogComponent>>(MatDialogRef);

  allowedRoles = [Roles.Admin, Roles.Admin_lbfa, Roles.Homologation];
  isAdmin = this.data.roles.some((role) => this.allowedRoles.includes(role));
}
