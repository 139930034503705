import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatDialogClose } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { AthleteSearchResult } from '@beathletics/api-interfaces';
import { ApiServiceService, SharedDataService } from '@beathletics/beathletics-data-state';
import { BeathleticsUiModule } from '@beathletics/beathletics-ui';
import { TranslocoDirective } from '@jsverse/transloco';
import { Observable, Subject, catchError, debounceTime, filter, of, switchMap, tap } from 'rxjs';
import { CompetitionResultFormService } from '../../services/competition-result-form.service';

@Component({
    selector: 'beathletics-competition-result-add-athlete',
    templateUrl: './competition-result-add-athlete.component.html',
    imports: [
        TranslocoDirective,
        MatFormField,
        MatLabel,
        MatInput,
        ReactiveFormsModule,
        FormsModule,
        BeathleticsUiModule,
        MatIconButton,
        MatIcon,
        MatSelect,
        MatOption,
        MatButton,
        MatDialogClose,
        AsyncPipe,
    ]
})
export class CompetitionResultAddAthleteComponent {
  #apiService = inject(ApiServiceService);
  #sharedService = inject(SharedDataService);
  #formService = inject(CompetitionResultFormService);

  @Input() nbAthletes?: number;
  @Input() isFromCombinedEvents = false;
  @Output() athleteSelected = new EventEmitter<{
    startingOrder: number | null;
    athletes: {
      id: string;
      order: number | null;
      bib: number;
      clubId?: string;
      category: string;
    }[];
  }>();

  loading = signal(false);
  error = signal(false);

  searchValue = new Subject<string>();
  searchAutocomplete$: Observable<AthleteSearchResult[]> = this.resetAthleteSearchValue();
  clubs$ = this.#sharedService.clubs$;
  categories$ = this.#sharedService.categories$;

  selectedAthletes: AthleteSearchResult[] = [];
  athletesBibs: { [order: number]: number } = {};
  athletesClubs: { [order: number]: string } = {};
  athletesCategories: { [order: number]: string } = {};
  startingOrder?: number;
  teamName = '';

  resetAthleteSearchValue() {
    const limitTo = { categories: this.#formService.allowedSearchCategories };
    return this.searchValue.pipe(
      tap(() => {
        this.loading.set(true);
        this.error.set(false);
      }),
      debounceTime(300),
      filter((value) => !(value && value.length < 2)),
      switchMap((value) =>
        value
          ? this.#apiService.findAthleteByNameOrLiveId(value, limitTo).pipe(
              catchError(() => {
                this.error.set(true);
                return of([]);
              }),
            )
          : of([]),
      ),
      tap(() => this.loading.set(false)),
    );
  }

  setAthlete(athlete: AthleteSearchResult) {
    this.selectedAthletes.push(athlete);
    this.athletesBibs[this.selectedAthletes.length] = athlete.bib || 0;
    this.athletesCategories[this.selectedAthletes.length] = athlete.person.currentCategory || '';
    this.athletesClubs[this.selectedAthletes.length] = athlete.club?.id || '';
  }

  removeAthlete(athleteId: string) {
    this.selectedAthletes = this.selectedAthletes.filter((athlete) => athlete.id !== athleteId);
  }

  validateAthletes() {
    if (this.startingOrder || this.isFromCombinedEvents) {
      const athletes = this.selectedAthletes.map((a, i) => ({
        id: a.id,
        order: (this.nbAthletes || 1) > 1 ? i + 1 : null,
        bib: this.athletesBibs[i + 1],
        clubId: this.athletesClubs[i + 1],
        category: a.person.currentCategory || '',
      }));
      this.athleteSelected.emit({
        startingOrder: this.startingOrder || null,
        athletes,
      });
    }
  }
}
