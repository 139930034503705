import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import {
  ERegistrationItemStatus,
  ERegistrationOrderStatus,
  IAthleteRegistrationWithCheck,
  IEventRegistration,
  IRegistrationAthleteItem,
  IRegistrationDisciplineItem,
} from '@beathletics/api-interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompetitionRegistrationService } from '../../services/competition-registration.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatCheckbox } from '@angular/material/checkbox';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@UntilDestroy()
@Component({
    selector: 'beathletics-competition-registrations-management',
    templateUrl: './competition-registrations-management.component.html',
    imports: [TranslocoDirective, MatCheckbox, ReactiveFormsModule, FormsModule, MatIconButton, MatIcon, MatButton]
})
export class CompetitionRegistrationsManagementComponent {
  #registrationService = inject(CompetitionRegistrationService);

  order!: { athletes: IAthleteRegistrationWithCheck[] };
  orderStatus = ERegistrationOrderStatus;
  itemStatus = ERegistrationItemStatus;
  selectedItems: IAthleteRegistrationWithCheck[] = [];
  showList = true;
  disableBtn = true;
  isAllSelected = false;
  deleteItem = false;
  selectedItem?: IAthleteRegistrationWithCheck | IRegistrationDisciplineItem;

  @Input() orderData?: {
    orderId: string;
    email: string;
    status: ERegistrationOrderStatus;
  };
  @Input() set registrations(registrations: { [athleteId: string]: IRegistrationAthleteItem }) {
    this.order = {
      athletes: Object.values(registrations).map((r) => ({
        ...r,
        checked: false,
        disciplines: Object.values(r.disciplines).map((d) => ({
          ...d,
          checked: false,
        })),
      })),
    };
  }

  @Output() closeDialog = new EventEmitter<IEventRegistration | undefined>();

  someChecked(athlete: IAthleteRegistrationWithCheck): boolean {
    return athlete.disciplines.filter((d) => d.checked).length > 0 && !athlete.checked;
  }

  checkAllDisciplines(athlete: IAthleteRegistrationWithCheck, checked: boolean): void {
    athlete.checked = checked;
    athlete.disciplines.forEach((d) => (d.checked = checked));
    this.verifySaveBtnStatus();
  }

  verifyIfAllChecked(athlete: IAthleteRegistrationWithCheck): void {
    athlete.checked = athlete.disciplines.every((d) => d.checked);
    this.verifySaveBtnStatus();
  }

  verifySaveBtnStatus(): void {
    this.disableBtn = this.order.athletes.every((a) => !a.checked && a.disciplines.every((d) => !d.checked));
  }

  confirmDeletion(item: IAthleteRegistrationWithCheck | IRegistrationDisciplineItem): void {
    this.selectedItem = item;
    this.deleteItem = true;
    this.showList = false;
  }

  deleteOrderItem(): void {
    if (this.selectedItem && this.orderData) {
      const itemIds = [];
      if ('liveId' in this.selectedItem) {
        itemIds.push(this.selectedItem.itemId);
        for (const discipline of this.selectedItem.disciplines) {
          itemIds.push(discipline.itemId);
        }
      } else {
        itemIds.push(this.selectedItem.itemId);
      }
      this.#registrationService
        .removeItemsFromOrder(this.orderData.orderId, itemIds)
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          this.closeDialog.emit(result);
        });
    }
  }

  validateSelection(): void {
    this.isAllSelected = this.order.athletes.every((a) => a.checked && a.disciplines.every((d) => d.checked));
    this.selectedItems = this.order.athletes.map((a) => ({
      ...a,
      disciplines: a.disciplines.filter((d) => d.checked),
    }));
    this.showList = false;
  }

  confirmValidation(): void {
    if (this.orderData) {
      const mailData = this.#registrationService.formatDataFormRegistrationResponseMail(
        this.orderData?.email,
        this.order.athletes,
      );
      this.#registrationService
        .validateOrCancelOrder(this.orderData.orderId, this.isAllSelected ? 'confirmation' : 'detailed', mailData)
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          this.closeDialog.emit(result);
        });
    }
  }
}
