import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import {
  DisciplineGroups,
  ERegistrationItemStatus,
  ICompetition,
  IRegistrationDisciplineTableRow,
} from '@beathletics/api-interfaces';
import { CompetitionRegistrationService } from '../../services/competition-registration.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';
import { BeathleticsUiModule } from '@beathletics/beathletics-ui';

@UntilDestroy()
@Component({
    selector: 'beathletics-competition-registration-discipline-table',
    templateUrl: './competition-registration-discipline-table.component.html',
    styles: [
        `
      th,
      td {
        padding: 0.75rem !important;
      }
    `,
    ],
    imports: [
        TranslocoDirective,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCheckbox,
        MatCellDef,
        MatCell,
        MatSortHeader,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatButton,
        MatDialogClose,
        BeathleticsUiModule,
    ]
})
export class CompetitionRegistrationDisciplineTableComponent implements AfterViewInit {
  #registrationService = inject(CompetitionRegistrationService);

  itemStatus = ERegistrationItemStatus;
  dataSource?: MatTableDataSource<IRegistrationDisciplineTableRow>;
  selection = new SelectionModel<IRegistrationDisciplineTableRow>(true, []);
  disciplineTableCols = ['select', 'name', 'category', 'club', 'userPerf', 'seasonBest', 'personalBest'];

  @ViewChild(MatSort) sort!: MatSort;
  @Input() competition?: ICompetition | null;
  @Input() discipline?: string;
  @Input() set data(data: IRegistrationDisciplineTableRow[]) {
    const disciplineType = data[0].group;
    if (disciplineType === DisciplineGroups.Relais) {
      const groupedData = this.#registrationService.groupAthletesByRelayTeamForDisciplineDetails(data);
      this.dataSource = new MatTableDataSource(groupedData);
    } else {
      this.dataSource = new MatTableDataSource(data);
    }
  }
  @Output() closeDialog = new EventEmitter<{
    itemsRejected: number;
    itemsValidated: number;
    orderCanceled: number;
    orderValidated: number;
  }>();

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  isAllSelected() {
    if (this.dataSource) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.filter(
        (row) => row.status !== this.itemStatus.REJECTED && row.status !== this.itemStatus.VALIDATED,
      ).length;
      return numSelected === numRows;
    }
    return false;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    if (this.dataSource) {
      this.selection.select(
        ...this.dataSource.data.filter(
          (row) => row.status !== this.itemStatus.REJECTED && row.status !== this.itemStatus.VALIDATED,
        ),
      );
    }
  }

  treatSelection(validate: boolean) {
    if (this.competition && this.discipline) {
      this.#registrationService
        .validateOrRefuseForDiscipline(
          validate,
          this.selection.selected.map((s) => ({
            athlete: s.name,
            athleteId: s.athleteId,
            contactEmail: s.orderEmail,
            discipline: this.discipline || '',
            eventDate: new Date(this.competition?.startDate || '').toLocaleDateString(),
            eventName: this.competition?.name || '',
            eventNumber: this.competition?.eventNumber || '',
            itemId: s.itemId,
            orderId: s.orderId,
            organizerEmail: this.competition?.registrationDetail?.contactEmail || '',
            organizerName: this.competition?.club?.name || this.competition?.federation?.name || '',
            status: validate ? 'accepté' : 'rejeté',
          })),
        )
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          this.closeDialog.emit(result);
        });
    }
  }
}
