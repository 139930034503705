<section *transloco="let t" class="w-[480px]">
  <header class="text-center">
    <h2 class="text-xl mb-4">Créer une compétition</h2>
    <hr />
  </header>
  <mat-stepper #competitionCreationStepper [orientation]="stepperOrientation" [linear]="true">
    <mat-step [stepControl]="eventNumberForm">
      <ng-template matStepLabel>Validation de l'eventNumber</ng-template>
      <div class="mt-[15px] pr-[36px]">
        <form [formGroup]="eventNumberForm" class="flex flex-col">
          <mat-form-field appearance="outline">
            <mat-label>EventNumber</mat-label>
            <input matInput formControlName="eventNumber" placeholder="EventNumber" required />
            @if (eventNumberForm.get('eventNumber')?.errors?.['minlength']) {
              <mat-error>* 3 caractères minimum</mat-error>
            }
          </mat-form-field>
          <div class="flex items-center">
            <button
              mat-raised-button
              color="primary"
              class="!mr-[25px]"
              [disabled]="
                eventNumberForm.get('eventNumber')?.errors?.['required'] ||
                eventNumberForm.get('eventNumber')?.errors?.['minlength']
              "
              (click)="testEventNumber()"
            >
              Soumettre l'eventNumber
            </button>
            @if (eventNumberValidation.loading) {
              <mat-spinner [diameter]="40"></mat-spinner>
            }
            @if (
              eventNumberForm.get('available')?.errors?.['required'] &&
              eventNumberValidation.tested &&
              !eventNumberValidation.loading
            ) {
              <mat-error class="text-xs italic font-bold">EventNumber déjà attribué</mat-error>
            }
          </div>
        </form>
      </div>
    </mat-step>

    <mat-step [stepControl]="competitionDataForm">
      <ng-template matStepLabel>Encodage des données</ng-template>
      <div class="mt-[15px] pr-[36px]">
        <beathletics-competition-data-form
          [(competitionData)]="competitionDataForm"
          [(organizer)]="competitionOrganizer"
        ></beathletics-competition-data-form>
        <div class="mt-[15px] flex justify-evenly">
          <button mat-button matStepperPrevious>Retour</button>
          <button mat-button color="primary" matStepperNext>Suivant</button>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Vérification</ng-template>
      <div class="mt-[15px] pr-[36px]">
        <mat-list>
          <mat-list-item>
            EventNumber : &nbsp;<span class="font-bold">{{ eventNumberForm.value?.eventNumber }}</span>
          </mat-list-item>
          <mat-list-item>
            Nom : &nbsp;<span class="font-bold">{{ competitionDataForm.value.name }}</span>
          </mat-list-item>
          <mat-list-item>
            Type : &nbsp;<span class="font-bold">{{ competitionDataForm.value.type }}</span>
          </mat-list-item>
          <mat-list-item>
            Site : &nbsp;<span class="font-bold">{{ competitionDataForm.value.venue }}</span>
          </mat-list-item>
          <mat-list-item>
            Date(s) : du&nbsp;
            <span class="font-bold">{{ competitionDataForm.value.startDate | date: 'dd/MM/yyyy' }}</span>
            &nbsp;au&nbsp;
            <span class="font-bold">{{ competitionDataForm.value.endDate | date: 'dd/MM/yyyy' }}</span>
          </mat-list-item>
          <mat-list-item>
            Organisateur : &nbsp;<span class="font-bold">{{ competitionDataForm.value.organizer || 'N.C.' }}</span>
          </mat-list-item>
          <mat-list-item> Commentaire : &nbsp; </mat-list-item>
          <mat-list-item class="font-bold ml-[25px]">{{ competitionDataForm.value.comment || ' - ' }}</mat-list-item>
        </mat-list>
        <div class="mt-[36px] flex justify-between">
          <button mat-raised-button color="accent" class="w-[110px]" (click)="resetStepperAndForm()">Reset Form</button>
          <button
            mat-raised-button
            color="primary"
            class="w-[110px]"
            (click)="saveCompetition()"
            [disabled]="competitionDataForm.invalid || eventNumberForm.invalid || savingCompetition"
          >
            @if (savingCompetition) {
              <mat-spinner [diameter]="20" color="primary" class="m-auto"></mat-spinner>
            } @else {
              Enregistrer
            }
          </button>
          <button mat-raised-button mat-dialog-close color="warn" class="w-[110px]">Annuler</button>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</section>
