<section *transloco="let t">
  @if (title) {
    <header class="text-center">
      <h2 class="mat-h2 !mb-2">{{ title }}</h2>
      <h3 class="text-sm text-gray-600 mb-2">{{ subtitle }}</h3>
      <hr />
    </header>
  }
  @switch (type) {
    @case ('athlete') {
      @if (itemData$ | async; as items) {
        @for (athlete of items | keyvalue; track athlete; let i = $index) {
          <div class="p-3">
            <div class="mt-3">
              @for (discipline of athlete.value.disciplines | keyvalue; track discipline; let last = $last) {
                <div [style.margin-bottom]="last ? '0' : '1rem'">
                  &bull;
                  <span
                    [style.text-decoration]="discipline.value.status === itemStatus.REJECTED ? 'line-through' : 'none'"
                    >{{ discipline.value.name }}</span
                  >
                  @if (discipline.value.status === itemStatus.REJECTED) {
                    <em class="text-gray-600"> &nbsp; ({{ t('BASIC_REFUSED') }}) </em>
                  }
                  @if (athleteDisciplines?.[discipline.key]?.data?.[athlete.key]?.metadata; as metadata) {
                    <br />
                    <ul class="text-sm text-gray-600">
                      <li>
                        {{ t('REGISTRATION_REF_PERF') }}:
                        {{ $any(metadata)?.personalRecordFormated || '-' }}
                      </li>
                      <li>{{ t('BASIC_COMMENT') }}: {{ metadata?.comment || '-' }}</li>
                    </ul>
                    @if ($any(metadata)?.subRecords) {
                      @for (subRecord of $any(metadata).subRecords | keyvalue: subRecordsSortFn; track subRecord) {
                        <div class="ml-5">
                          →
                          {{ competitionService.getLoadedDisciplineByRef($any(subRecord.key))?.name }}
                          <ul class="text-sm text-gray-600">
                            <li>
                              {{ t('REGISTRATION_REF_PERF') }}:
                              {{
                                ($any(subRecord.value)?.personalRecord
                                  | perfFormatter
                                    : competitionService.getLoadedDisciplineByRef(subRecord.key)?.eventType?.result_type
                                    : $any(
                                        competitionService.getLoadedDisciplineByRef(subRecord.key)?.eventType
                                          ?.discipline_group
                                      )) || '-'
                              }}
                            </li>
                            <li>
                              {{ t('BASIC_COMMENT') }}:
                              {{ $any(subRecord.value)?.comment || '-' }}
                            </li>
                          </ul>
                        </div>
                      }
                    }
                  }
                </div>
              }
            </div>
          </div>
        }
      } @else {
        <div class="p-6 flex justify-center items-center">
          <mat-spinner></mat-spinner>
        </div>
      }
    }
    @case ('discipline') {
      @if (disciplineData$ | async; as disciplineData) {
        <beathletics-competition-registration-discipline-table
          [competition]="competition$ | async"
          [discipline]="disciplineName"
          [data]="disciplineData"
          (closeDialog)="close($event)"
        ></beathletics-competition-registration-discipline-table>
      } @else {
        <div class="p-6 flex justify-center items-center">
          <mat-spinner></mat-spinner>
        </div>
      }
    }
    @case ('order') {
      @if (itemData$ | async; as items) {
        <beathletics-competition-registrations-management
          [orderData]="orderData"
          [registrations]="items"
          (closeDialog)="close($event)"
        ></beathletics-competition-registrations-management>
      } @else {
        <div class="p-6 flex justify-center items-center">
          <mat-spinner></mat-spinner>
        </div>
      }
    }
  }
</section>
