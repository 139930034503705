<div class="w-full">
  <mat-form-field class="w-full" [appearance]="appearance">
    <mat-label>{{ 'SEARCH_COMPETITION' | transloco }}</mat-label>
    <input
      type="text"
      spellcheck="false"
      [placeholder]="'SEARCH_COMPETITION_PLACEHOLDER' | transloco"
      matInput
      #searchInput
      [matAutocomplete]="autocomplete"
      (click)="$any($event).target.setSelectionRange(0, $any($event).target.value.length)"
      (keyup)="onSearchChange($any($event).target.value)"
    />
    <mat-autocomplete
      #autocomplete="matAutocomplete"
      connectedTo="#searchInput"
      (optionSelected)="onSearchSelect($event.option.value); searchInput.value = ''"
      (opened)="setIsOpenParam(true)"
      (closed)="setIsOpenParam(false)"
    >
      @if (!searchAutocomplete().error || !searchAutocomplete().loading) {
        @if (searchAutocomplete() && searchAutocomplete().competitions.length > 0) {
          @for (event of searchAutocomplete().competitions; track event.id) {
            <mat-option [value]="event"> [{{ event.eventNumber }}] {{ event.name }} </mat-option>
          }
        } @else {
          @if (isOpen === true || searchAutocomplete().error) {
            @if (!searchAutocomplete().error && !searchAutocomplete().loading) {
              <mat-option>{{ 'SEARCH_NO_RESULT_FOR' | transloco: { string: currentSearchValue } }}</mat-option>
            }
            @if (searchAutocomplete().error) {
              <mat-option disabled>{{ 'ERROR_PLEASE_RETRY' | transloco }}</mat-option>
            }
          }
        }
      }
    </mat-autocomplete>
    @if (!searchAutocomplete().loading && !searchAutocomplete().error) {
      <mat-icon matSuffix>search</mat-icon>
    } @else if (searchAutocomplete().loading) {
      <mat-spinner matSuffix diameter="20" class="mr-4"></mat-spinner>
    } @else {
      <button matSuffix mat-icon-button (click)="$event.stopPropagation(); retry()">
        <mat-icon>refresh</mat-icon>
      </button>
    }
  </mat-form-field>
</div>
