<section>
  <h3>Creation d'un nouvel utilisateur</h3>

  <form [formGroup]="createUserForm">

    <div>
      <mat-form-field>
        <mat-label>Prénom</mat-label>
        <input matInput formControlName="firstname" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nom</mat-label>
        <input matInput formControlName="lastname" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Role</mat-label>
        <mat-select formControlName="role">
          @for (role of roles; track role) {
            <mat-option [value]="role"></mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="email" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Mot de passe</mat-label>
        <input type="password" matInput formControlName="password" />
      </mat-form-field>
    </div>

  </form>
</section>
