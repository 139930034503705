import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'beathletics-competition-registrations-local-team-form',
    template: `
    <ng-container *transloco="let t">
      <form [formGroup]="localTeamForm" class="w-80 flex flex-col">
        <div class="text-center mb-6">
          <h3 class="text-gray-600 font-medium">
            {{ t('REGISTRATION_CREATE_TEAM') }}
          </h3>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>{{ t('BASIC_TEAM_NAME') }}</mat-label>
          <input matInput formControlName="name" required />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ t('BASIC_ABBREV') }}</mat-label>
          <input matInput formControlName="abbr" required />
        </mat-form-field>

        <div class="flex justify-between">
          <button mat-raised-button color="warn" mat-dialog-close>
            {{ t('BASIC_CANCEL') }}
          </button>
          <button mat-raised-button color="primary" [disabled]="!localTeamForm.valid" (click)="saveNewTeam()">
            {{ t('BASIC_CONFIRM') }}
          </button>
        </div>
      </form>
    </ng-container>
  `,
    imports: [TranslocoDirective, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatButton, MatDialogClose]
})
export class CompetitionRegistrationsLocalTeamFormComponent {
  dialogRef = inject(MatDialogRef<CompetitionRegistrationsLocalTeamFormComponent>);

  localTeamForm = new FormGroup({
    name: new FormControl(''),
    abbr: new FormControl(''),
  });

  saveNewTeam() {
    this.dialogRef.close(this.localTeamForm.value);
  }
}
