<div *transloco="let t" class="w-full flex flex-col mt-5">
  <!-- TODO : add a disclaimer like 'selected disciplines : N' ??? -->

  <beathletics-prime-tree-selector
    [appendTo]="appendTo"
    paddingBottom="0.3rem"
    [paddingTop]="'1rem'"
    [selectionMode]="'checkbox'"
    [label]="t('DISCIPLINE_BY_GROUP')"
    [dataTree]="disciplinesByGroup"
    [titleAndSubtitleLabel]="{ titleProp: 'name', subtitleProp: 'catList' }"
    [closeIcon]="'check_box'"
    [dataRefProperty]="'shopReference'"
    [selectedData]="_selectedDisciplineRefs()"
    [filterParents]="true"
    (selectionChange)="selectDisciplines($event)"
  ></beathletics-prime-tree-selector>

  <!-- (selectionChange)="dis.emit($any($event))" -->
</div>
