import { KeyValue, AsyncPipe, KeyValuePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ERegistrationItemStatus,
  ERegistrationOrderStatus,
  IEventRegistration,
  IRegistrationAthleteItem,
  IRegistrationDataAthlete,
  IRegistrationDataDiscipline,
  IRegistrationDataOrder,
  IRegistrationDisciplineTableRow,
  RegistrationAthleteInfo,
} from '@beathletics/api-interfaces';
import { NewCompetitionService } from '@beathletics/beathletics-data-state';
import { Observable, map } from 'rxjs';
import { CompetitionRegistrationService } from '../../services/competition-registration.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CompetitionRegistrationDisciplineTableComponent } from '../competition-registration-discipline-table/competition-registration-discipline-table.component';
import { CompetitionRegistrationsManagementComponent } from '../competition-registrations-management/competition-registrations-management.component';
import { BeathleticsUiModule } from '@beathletics/beathletics-ui';

@Component({
    selector: 'beathletics-competition-registration-item-dialog',
    templateUrl: './competition-registration-item-dialog.component.html',
    imports: [
        TranslocoDirective,
        MatProgressSpinner,
        CompetitionRegistrationDisciplineTableComponent,
        CompetitionRegistrationsManagementComponent,
        AsyncPipe,
        KeyValuePipe,
        BeathleticsUiModule,
    ]
})
export class CompetitionRegistrationItemDialogComponent {
  data = inject<IRegistrationDataAthlete | IRegistrationDataDiscipline | IRegistrationDataOrder>(MAT_DIALOG_DATA);
  competitionService = inject(NewCompetitionService);
  private dialogRef = inject<MatDialogRef<CompetitionRegistrationItemDialogComponent>>(MatDialogRef);
  private registrationService = inject(CompetitionRegistrationService);

  itemStatus = ERegistrationItemStatus;
  loading = true;
  type: 'athlete' | 'discipline' | 'order' | undefined;
  title?: string;
  subtitle?: string;
  orderData?: {
    orderId: string;
    email: string;
    status: ERegistrationOrderStatus;
  };
  competition$ = this.competitionService.selectedCompetitionData$;
  itemData$: Observable<{
    [athleteId: string]: IRegistrationAthleteItem;
  }> | null = null;
  disciplineData$: Observable<IRegistrationDisciplineTableRow[]> | null = null;
  disciplineName?: string;
  athleteDisciplines?: {
    [disciplineRef_orderId: string]: IRegistrationDataDiscipline;
  } = {};

  constructor() {
    const data = this.data;

    if ('athleteId' in data) {
      this.type = 'athlete';
      this.title = 'Inscriptions pour ' + data.name;
      this.subtitle = `${data.category}  -  ${data.club}`;
      if (data.isOneDayBib) {
        const metadata = data.data.metadata as RegistrationAthleteInfo;
        this.subtitle += `  (${metadata.localTeam || metadata.federation})`;
      }
      this.athleteDisciplines = data.disciplines?.reduce(
        (acc, d) => ({ ...acc, [d.disciplineRef + d.orderId]: d }),
        {},
      );
    } else if ('disciplineRef' in data) {
      this.type = 'discipline';
      this.disciplineName = data.name;
      this.title = 'Inscriptions au ' + data.name;
      this.subtitle = data.categories.join(', ');
    } else if ('orderId' in data) {
      this.orderData = {
        orderId: data.orderId,
        email: data.email,
        status: data.data.status,
      };
      this.type = 'order';
      this.title = 'Commande de ' + data.email;
      this.subtitle = data.date?.toLocaleString();
    }
    if (this.type) {
      this.itemData$ = this.registrationService.getRegistrationDetailsByType(this.type, data);
      if ('disciplineRef' in data && this.itemData$) {
        this.disciplineData$ = this.itemData$.pipe(
          map((d) =>
            Object.values(d).map((a) => {
              const discipline = a.disciplines[data.disciplineRef];
              return {
                ...discipline,
                name: a.name,
                category: a.category,
                club: a.club,
                liveId: a.liveId,
              };
            }),
          ),
        );
      }
    }
  }

  subRecordsSortFn = (
    a: KeyValue<
      string,
      {
        personalRecord: string | number;
        comment?: string;
      }
    >,
    b: KeyValue<
      string,
      {
        personalRecord: string | number;
        comment?: string;
      }
    >,
  ) => {
    const disciplineA = this.competitionService.getLoadedDisciplineById(a.key);
    const disciplineB = this.competitionService.getLoadedDisciplineById(b.key);
    const parent = this.competitionService.getLoadedDisciplineById(disciplineA?.parentId);
    const indexA = parent?.children?.findIndex((c) => c.id === disciplineA?.id);
    const indexB = parent?.children?.findIndex((c) => c.id === disciplineB?.id);
    return (indexA || 0) - (indexB || 0);
  };

  close(
    order?:
      | IEventRegistration
      | {
          itemsRejected: number;
          itemsValidated: number;
          orderCanceled: number;
          orderValidated: number;
        },
  ): void {
    this.dialogRef.close(order);
  }
}
