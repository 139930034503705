<section>
  @if (data) {
    <form [formGroup]="editUserForm">
      <h3 class="text-center text-xl font-bold mb-4">
        {{ data.username }}
      </h3>
      <hr />
      <div mat-dialog-content>
        <div>
          <h4 class="my-4 font-bold">Informations :</h4>
          <div>
            <mat-form-field class="w-1/2">
              <mat-label>Nom</mat-label>
              <input matInput formControlName="lastName" />
            </mat-form-field>
            <mat-form-field class="w-1/2">
              <mat-label>Prénom</mat-label>
              <input matInput formControlName="firstName" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="w-full">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="w-full">
              <mat-label>Live ID</mat-label>
              <input matInput formControlName="liveId" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="w-1/2">
              <mat-label>Club</mat-label>
              <mat-select formControlName="club">
                @for (club of clubs$ | async; track club) {
                  <mat-option [value]="club.abbr">{{ club.abbr }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field class="w-1/2">
              <mat-label>Province</mat-label>
              <mat-select formControlName="province">
                @for (province of provinces; track province) {
                  <mat-option [value]="province">{{ province }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex justify-between mb-6">
            <button mat-raised-button color="primary" (click)="updateUser()" [disabled]="!editUserForm.dirty">
              Enregistrer les modifications
            </button>
            <button mat-raised-button color="warn" (click)="closeDialog()">Annuler</button>
          </div>
        </div>
        <hr />
        <div>
          <h4 class="mt-4 font-bold">Roles :</h4>
          <div>
            <mat-list>
              @for (role of userRoles; track role) {
                <mat-list-item>
                  <div class="flex justify-between items-center">
                    <span>{{ role.name | uppercase }}</span>
                    <button mat-icon-button color="warn" (click)="removeRole(role)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </mat-list-item>
              }
            </mat-list>
          </div>
          <div>
            <h5 class="my-4 font-bold">Ajouter un rôle :</h5>
            <div class="flex justify-between items-center">
              <mat-form-field appearance="outline">
                <mat-label>Rôles</mat-label>
                <mat-select [(ngModel)]="selectedRole" [ngModelOptions]="{ standalone: true }">
                  @for (role of availableRoles; track role) {
                    <mat-option [value]="role">{{ role.name }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <div class="pb-4">
                <button mat-raised-button color="primary" (click)="addRole()" [disabled]="!selectedRole">
                  Ajouter le rôle
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <h4 class="my-4 font-bold">Réinitialiser le mot de passe :</h4>
          <div class="flex flex-col justify-center">
            <mat-form-field>
              <mat-label>Durée de vie du lien (en heures)</mat-label>
              <input
                matInput
                [(ngModel)]="mailLinkLifespan"
                [ngModelOptions]="{ standalone: true }"
                type="number"
                step="1"
                min="1"
                autocomplete="off"
              />
            </mat-form-field>
            <button mat-raised-button color="accent" [disabled]="!mailLinkLifespan" (click)="sendPasswordResetMail()">
              Envoyer email de réinitialisation
            </button>
          </div>
        </div>
      </div>
    </form>
  } @else {
    <div>Chargement ...</div>
  }
</section>
