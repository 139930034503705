<section class="w-[500px] text-center">
  <h3 class="font-bold mb-[15px]">{{ alert.title }}</h3>

  <hr />

  <p class="m-[15px] mb-[25px]">{{ alert.text }}</p>

  <div class="flex justify-evenly">
    <button mat-raised-button color="primary" (click)="closeDialog(false)">
      Annuler
    </button>
    <button mat-raised-button color="warn" (click)="closeDialog(true)">
      Supprimer
    </button>
  </div>
</section>
