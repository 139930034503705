import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { EPerfType, EResultFormType, IDiscipline, INewTrials, IResult, Roles } from '@beathletics/api-interfaces';
import { NewCompetitionService } from '@beathletics/beathletics-data-state';
import {
  highJumpHeightValidationRegex,
  markCertifiablePerfFromTrialsArrayFront,
  verifyResultPrecision,
} from '@beathletics/utils-shared';
import {
  highJumpHeightValidator,
  hightJumpNoDuplicateValidator,
  resultPrecisionValidator,
} from '../../../../../shared/custom-input-validators.directive';
import { CompetitionResultFormService } from '../../services/competition-result-form.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { CompetitionResultAddAthleteComponent } from '../competition-result-add-athlete/competition-result-add-athlete.component';
import { CompetitionResultEditFormComponent } from '../competition-result-edit-form/competition-result-edit-form.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatButton } from '@angular/material/button';
import { FrontAuthModule } from '@beathletics/auth';

@Component({
    selector: 'beathletics-competition-result-edit-dialog',
    templateUrl: './competition-result-edit-dialog.component.html',
    imports: [
        TranslocoDirective,
        CompetitionResultAddAthleteComponent,
        CompetitionResultEditFormComponent,
        MatSlideToggle,
        ReactiveFormsModule,
        FormsModule,
        MatButton,
        MatDialogClose,
        FrontAuthModule,
    ]
})
export class CompetitionResultEditDialogComponent {
  roles = Roles;
  noEventTypeError = false;
  resultForm = this.setForm();
  formType: EResultFormType | undefined;
  formTypes = EResultFormType;
  windMode: string | undefined;
  resultPrecision: number | undefined;
  isFromCombinedEvents = false;
  trials: INewTrials[] | undefined;
  hjTrials:
    | {
        height: string;
        trials: (
          | INewTrials
          | {
              rankingPerf: undefined;
              windSpeed: undefined;
              trialData: undefined;
            }
        )[];
      }[]
    | undefined;
  hjHeightForm: FormGroup | undefined;
  recalculateRanks = true;
  adminDelete = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      discipline: IDiscipline;
      result: IResult;
      addResult: boolean;
    },
    public resultFormService: CompetitionResultFormService,
    private fb: FormBuilder,
    private competitionService: NewCompetitionService,
    private dialogService: DialogService,
  ) {
    resultFormService.allowedSearchCategories = this.data.discipline.categories?.map((c) => c.abbr);
  }

  setForm() {
    if (this.data.discipline.eventType) {
      // 1) define type of form (classic, HJ, combined ?, relay ?)
      this.formType = this.resultFormService.getFormType(this.data.discipline);
      // 2) check eventType data (wind & precision)
      this.windMode = this.data.discipline.eventType.wind_mode;
      this.resultPrecision = ('' + this.data.discipline.eventType.precision)?.length - 1 || 0;
      // 3) check if result is from combined events
      this.isFromCombinedEvents = this.data.discipline?.parentId ? true : false;
      // ?? in the future, add a "isFromChallenge" to edit challenge points ??
      this.trials = this.data.result.newTrials
        ? structuredClone(this.data.result.newTrials)
        : [
            {
              rankingPerf: '0',
              windSpeed: null,
              best: false,
              valid: true,
              perftype: this.resultFormService.getPerfTypeFromFormType(this.formType),
            } as INewTrials,
          ];
      // 4) build form from data
      return this.buildForm(
        this.data.result,
        this.formType,
        this.windMode,
        this.resultPrecision,
        this.isFromCombinedEvents,
      );
    } else {
      this.noEventTypeError = true;
      return undefined;
    }
  }

  buildForm(
    result: IResult,
    formType: EResultFormType,
    windMode: string,
    precision: number,
    isFromCombinedEvents = false,
  ) {
    const form = {} as any;
    // 1) set identical fields
    form['rank'] = new FormControl(result?.rank);
    form['feature'] = new FormControl(result?.competitionFeature);
    form['comment'] = new FormControl(result?.modificationComment, Validators.minLength(10));
    // 2) set combined event specific fields
    if (isFromCombinedEvents) {
      form['points'] = new FormControl(result?.combinedPoints);
      // 3) check if starting order or heat number is necessary
      if (this.data.addResult) {
        form['startingOrder'] = new FormControl(null);
        if (this.data.discipline.disciplineGroup === 'Race') {
          form['heatNumber'] = new FormControl(null);
        }
      }
    }
    // 4) set result trials fields (perf & wind? by trial)
    const trials = result.newTrials || [
      {
        rankingPerf: undefined,
        windSpeed: undefined,
        trialData: undefined,
        perftype: this.resultFormService.getPerfTypeFromFormType(formType),
      },
    ];

    if (formType !== EResultFormType.HIGH_JUMP) {
      for (const [i, trial] of trials.entries()) {
        const rankingPerf = trial.rankingPerf ? +trial.rankingPerf : undefined;
        const perf =
          formType === EResultFormType.RACE && typeof rankingPerf === 'number' ? rankingPerf / 1000 : rankingPerf;
        form['perf' + i] = new FormControl(
          {
            value: verifyResultPrecision(perf, precision),
            disabled: formType === EResultFormType.COMBINED_TOTAL && !this.data.addResult,
          },
          resultPrecisionValidator(precision),
        );
        if (windMode === 'I' || windMode === 'H') {
          form['wind' + i] = new FormControl(trial?.windSpeed);
        }
      }
    } else {
      const trialsByHeight: Map<
        string,
        (
          | INewTrials
          | {
              rankingPerf: undefined;
              windSpeed: undefined;
              trialData: undefined;
            }
        )[]
      > = new Map();
      const highJumpHeights = [];
      for (const trial of trials) {
        const rankingPerf = trial.rankingPerf ? +trial.rankingPerf : undefined;
        const perf = verifyResultPrecision(rankingPerf, precision);
        const heighTrials = trialsByHeight.get(perf) || [];
        heighTrials.push(trial);
        if (!trialsByHeight.has(perf)) {
          highJumpHeights.push(rankingPerf);
          trialsByHeight.set(perf, heighTrials);
        }
      }

      this.hjTrials =
        [...trialsByHeight.entries()].map(([height, trials]) => ({
          height,
          trials,
        })) || [];
      this.hjHeightForm = this.fb.group({
        height: new FormControl('', [
          highJumpHeightValidator(highJumpHeightValidationRegex),
          hightJumpNoDuplicateValidator(this.hjTrials.map((h) => h.height)),
        ]),
      });
      for (const res of this.hjTrials) {
        form['height' + res.height] = new FormControl(res.height, [
          highJumpHeightValidator(highJumpHeightValidationRegex),
          hightJumpNoDuplicateValidator(highJumpHeights, res.height),
        ]);

        for (const n of [0, 1, 2]) {
          form[`value${res.height}.${n}`] = new FormControl(res.trials?.[n]?.trialData || '');
        }
      }
    }
    return this.fb.group(form);
  }

  saveModifications() {
    if (this.resultForm && this.formType) {
      const formData = { ...(this.resultForm.value as Record<string, any>) };
      const updatedResult = JSON.parse(JSON.stringify(this.data.result)) as IResult;
      updatedResult.rank = formData['rank'];
      updatedResult.competitionFeature = formData['feature'];
      updatedResult.modificationComment = formData['comment'];
      if (formData['startingOrder']) {
        updatedResult.startingOrder = formData['startingOrder'];
      }
      if (formData['heatNumber']) {
        updatedResult.heatNumber = formData['heatNumber'];
      }

      if (this.formType !== EResultFormType.HIGH_JUMP) {
        updatedResult.newTrials = this.trials || [];
        const nbPerf = Object.keys(formData).filter((key) => key.includes('perf'));
        const trials: INewTrials[] = updatedResult.newTrials.map((t) => ({
          ...t,
          best: false,
        }));
        let bestPerf = {
          value: 0,
          wind: 0,
          index: 0,
        };

        for (const [i, perf] of nbPerf.entries()) {
          const perfValue = !isNaN(+formData[perf]) ? +formData[perf] : 0;
          const windValue = !isNaN(formData['wind' + i]) ? +formData['wind' + i] : 0;
          if (i === 0) {
            bestPerf = {
              value: perfValue,
              wind: windValue,
              index: i,
            };
          }

          if (i !== 0 && perfValue >= bestPerf.value) {
            bestPerf = {
              value: perfValue,
              wind: windValue,
              index: i,
            };
          }

          if (trials?.[i]) {
            trials[i].rankingPerf =
              this.formType === EResultFormType.RACE && !isNaN(+formData[perf])
                ? +formData[perf] * 1000
                : isNaN(+formData[perf])
                  ? formData[perf]
                  : +formData[perf];
            if (this.windMode !== 'N') {
              trials[i].windSpeed = formData['wind' + i];
            }
            trials[i].best = false;
          } else {
            const trial = {
              rankingPerf:
                this.formType === EResultFormType.RACE && !isNaN(+formData[perf])
                  ? +formData[perf] * 1000
                  : isNaN(+formData[perf])
                    ? formData[perf]
                    : +formData[perf],
              perftype: this.resultFormService.getPerfTypeFromFormType(this.formType),
              best: false,
              valid: true,
              attemptNumber: i + 1,
              windSpeed: undefined as string | undefined,
            };
            if (this.windMode !== 'N') {
              trial.windSpeed = windValue + '';
            }
            trials.push(trial as INewTrials);
          }
        }
        trials[bestPerf.index].best = true;
        updatedResult.newTrials = trials.sort((a, b) => (a?.attemptNumber || 0) - (b?.attemptNumber || 0));
      } else {
        const hjResults: INewTrials[] = [];
        let bestHeight = 0;

        if (this.hjTrials)
          for (const jump of this.hjTrials) {
            const height = formData[`height${jump.height}`];

            for (const n of [0, 1, 2]) {
              if (formData[`value${jump.height}.${n}`]) {
                if (+height > bestHeight && formData[`value${jump.height}.${n}`] === '0') {
                  bestHeight = height;
                }
                hjResults.push({
                  rankingPerf: height,
                  perftype: this.resultFormService.getPerfTypeFromFormType(this.formType),
                  best: false,
                  valid: true,
                  attemptNumber: n + 1,
                  trialData: formData[`value${jump.height}.${n}`],
                } as INewTrials);
              }
            }
          }
        const bestPerf = hjResults.findIndex((r) => +(r.rankingPerf || 0) === +bestHeight && r.trialData === '0');
        if (bestPerf !== -1) {
          hjResults[bestPerf].best = true;
        }
        updatedResult.newTrials = hjResults as INewTrials[];
      }
      updatedResult.newTrials && markCertifiablePerfFromTrialsArrayFront(updatedResult.newTrials);

      let disciplineParentId = undefined;
      if (
        (this.isFromCombinedEvents || this.data.discipline.parentId) &&
        (formData?.['points'] !== updatedResult.combinedPoints || this.data.addResult)
      ) {
        updatedResult.combinedPoints = +formData['points'];
        disciplineParentId = this.data.discipline?.parentId;
      }

      const eventNumber = this.competitionService.getSelectedCompetitionSnapshot().eventNumber;
      if (!this.data.addResult) {
        this.competitionService.editResultData(eventNumber, updatedResult, this.recalculateRanks, disciplineParentId);
      } else if (this.resultFormService.selectedAthletes) {
        if (this.formType !== EResultFormType.COMBINED_TOTAL && !this.data.discipline.parentId) {
          this.competitionService.createNewResult(
            eventNumber,
            { ...updatedResult, discipline: this.data.discipline },
            this.resultFormService.selectedAthletes,
            this.recalculateRanks,
          );
          this.resultFormService.selectedAthletes = undefined;
        } else {
          // * combined events => push result to temporary event children
          if (this.resultFormService.temporaryCombinedEvents) {
            this.resultFormService.temporaryCombinedEvents.children.push({
              ...updatedResult,
              heatNumber: updatedResult.heatNumber ? updatedResult.heatNumber : 1,
              discipline: {
                eventType: this.data.discipline.eventType,
              } as IDiscipline,
            });

            const parent = this.resultFormService.temporaryCombinedEvents.total;
            if (
              this.resultFormService.temporaryCombinedEvents.children.length === parent.discipline?.children?.length
            ) {
              const totalPoints = this.resultFormService.temporaryCombinedEvents.children.reduce(
                (acc, curr) => acc + (curr.combinedPoints || 0),
                0,
              );
              const newResult = {
                ...parent,
                newTrials: [
                  {
                    best: true,
                    valid: true,
                    perftype: EPerfType.POINTS,
                    homologationBest: true,
                    homologationValid: true,
                    rankingPerf: totalPoints,
                  },
                ],
              } as any;
              this.competitionService.createNewResult(
                eventNumber,
                newResult,
                this.resultFormService.selectedAthletes,
                this.recalculateRanks,
                this.resultFormService.temporaryCombinedEvents.children,
              );
              this.resultFormService.selectedAthletes = undefined;
            } else {
              // * reload the dialog with the next child until all children are added
              const discipline =
                parent.discipline?.children?.[this.resultFormService.temporaryCombinedEvents.children.length];
              const baseResult = this.competitionService.getLoadedResultById(discipline?.results?.[0]?.id ?? '');
              if (discipline) {
                this.dialogService.openCompetitionEditResultDialog(
                  {
                    discipline,
                    result: this.resultFormService.setBaseResultData(baseResult),
                  },
                  true,
                );
              }
            }
          }
        }
      }
    }
  }

  setAddAthleteData(data: {
    startingOrder: number | null;
    athletes: {
      id: string;
      order: number | null;
      bib: number;
      clubId?: string;
      category: string;
    }[];
  }) {
    this.data.result.startingOrder = data.startingOrder;
    this.resultFormService.selectedAthletes = data.athletes;
  }

  handleDeleteBtn(ok: boolean) {
    if (ok) {
      this.resultForm?.disable();
      this.adminDelete = true;
    } else {
      this.resultForm?.enable();
      if (this.recalculateRanks) {
        this.resultForm?.controls['rank'].disable();
      }
      if (this.data.discipline.disciplineGroup === 'CombinedDiscipline') {
        this.resultForm?.controls['perf0'].disable();
      }
      this.adminDelete = false;
    }
  }

  deleteResult() {
    const eventNumber = this.competitionService.getSelectedCompetitionSnapshot().eventNumber;
    this.competitionService.deleteEventResult(eventNumber, this.data.result.id, this.recalculateRanks);
  }
}
