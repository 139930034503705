<section class="w-[220px]">
  <header class="text-center">
    <h2 class="font-bold text-lg">Sélectionner un event type</h2>
  </header>
  <hr class="my-[15px]" />
  @if (eventTypes$ | async) {
    <div>
      <div>
        <h4 class="font-semibold">1) Type de lieu</h4>
        <mat-button-toggle-group
          [(ngModel)]="venueType"
          (change)="filterEventTypesByVenue($event)"
          class="my-[15px] w-full"
          >
          <mat-button-toggle value="I" class="w-1/2">INDOOR</mat-button-toggle>
          <mat-button-toggle value="O" class="w-1/2">OUTDOOR</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      @if (venueType) {
        <div>
          <label class="font-semibold">2) Type de discipline</label>
          <mat-radio-group
            [(ngModel)]="disciplineType"
            color="primary"
            class="flex flex-col items-start my-[15px]"
            (change)="filterEventTypesByGroup($event)"
            >
            @for (group of disciplineGroups; track group) {
              <mat-radio-button
                [value]="group"
                >
                {{ group }}
              </mat-radio-button>
            }
          </mat-radio-group>
        </div>
      }
      @if (disciplineType) {
        <div>
          @if (filteredEventTypes$ | async; as eventTypes) {
            <h4 class="font-semibold">3) Choisir l'event type</h4>
            <mat-form-field appearance="outline" class="my-[15px] w-full">
              <mat-label>Event Type</mat-label>
              <mat-select (selectionChange)="selectEventType($event)">
                <!-- TODO : better display eventTypes cause too many identical entries in the lists -->
                @for (eventType of eventTypes; track eventType) {
                  <mat-option
                    [value]="eventType"
                    >{{ eventType.name_fr }} ({{ eventType.national_code }})
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          } @else {
            <mat-spinner [diameter]="60"></mat-spinner>
          }
        </div>
      }
      <div class="flex justify-between">
        <button
          mat-raised-button
          color="primary"
          (click)="validateEventType()"
          [disabled]="!selectedEventType"
          >
          Enregistrer
        </button>
        <button mat-raised-button color="warn" mat-dialog-close>Annuler</button>
      </div>
    </div>
  } @else {
    <div class="text-center">
      <p class="text-base italic">
        Les event types n'ont pas été chargés correctement.
        <br />
        Veuillez recharger la page et ré-essayer.
      </p>
      <button
        mat-raised-button
        mat-dialog-close
        color="primary"
        class="!mt-[20px]"
        >
        OK
      </button>
    </div>
  }
</section>
