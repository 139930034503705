<section>
  <header class="text-center">
    <h2 class="text-xl mb-4">Fusionner deux athlètes</h2>
    <hr />
  </header>
  <div class="flex">
    <div class="w-80">
      <h3 class="text-center font-medium m-4">
        Athlète à <span class="underline">SUPPRIMER</span>
      </h3>
      <beathletics-athlete-search-bar
        action="RETURN"
        [searchAutocomplete]="$any(search[1].autocomplete$ | async) || []"
        (searchValue)="search[1].value.next($event)"
        [loading]="search[1].loading()"
        [error]="search[1].error()"
        (athleteToReturn)="search[1].selected = $event"
      ></beathletics-athlete-search-bar>
      <mat-card class="h-40">
        @if (search[1].selected; as a1) {
          <mat-card-header>
            <mat-card-title>{{ a1.firstAndLastName }}</mat-card-title>
            <mat-card-subtitle>Life ID : {{ a1.liveId }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <ul>
              <li>
                Date de naissance :
                {{ a1.person.birthDate | date : 'dd/MM/yyy' }}
              </li>
              <li>Catégorie : {{ a1.person.currentCategory }}</li>
              <li>Club : {{ a1.club?.abbr || 'NA' }}</li>
            </ul>
          </mat-card-content>
        } @else {
          <mat-card-content class="h-full !flex justify-center items-center"
            ><em>Aucun athlète a supprimer sélectionné</em></mat-card-content
            >
          }
        </mat-card>
      </div>

      <div class="flex flex-col justify-evenly pt-32">
        <mat-icon class="m-4">double_arrow</mat-icon>
        <mat-icon class="m-4">double_arrow</mat-icon>
        <mat-icon class="m-4">double_arrow</mat-icon>
      </div>

      <div class="w-80">
        <h3 class="text-center font-medium m-4">
          Athlète à <span class="underline">CONSERVER</span>
        </h3>
        <beathletics-athlete-search-bar
          action="RETURN"
          [searchAutocomplete]="$any(search[2].autocomplete$ | async) || []"
          (searchValue)="search[2].value.next($event)"
          [loading]="search[2].loading()"
          [error]="search[2].error()"
          (athleteToReturn)="search[2].selected = $event"
        ></beathletics-athlete-search-bar>
        <mat-card class="h-40">
          @if (search[2].selected; as a2) {
            <mat-card-header>
              <mat-card-title>{{ a2.firstAndLastName }}</mat-card-title>
              <mat-card-subtitle>Life ID : {{ a2.liveId }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <ul>
                <li>
                  Date de naissance :
                  {{ a2.person.birthDate | date : 'dd/MM/yyy' }}
                </li>
                <li>Catégorie : {{ a2.person.currentCategory }}</li>
                <li>Club : {{ a2.club?.abbr || 'NA' }}</li>
              </ul>
            </mat-card-content>
          } @else {
            <mat-card-content class="h-full !flex justify-center items-center"
              ><em>Aucun athlète a conserver sélectionné</em></mat-card-content
              >
            }
          </mat-card>
        </div>
      </div>

      <div class="text-center">
        <hr class="my-4" />
        <em class="text-red-800">
          @if (
            search[1].selected &&
            search[2].selected &&
            search[1].selected.id === search[2].selected.id) {
            Les deux athlètes sélectionnés sont les mêmes (ID
            identiques)
          } @else {
            Vérifiez bien les athlètes sélectionnés avant de fusionner, cette
            action est irréversible
          }
        </em>
        <hr class="my-4" />
      </div>

      <div class="flex justify-between">
        <button mat-raised-button mat-dialog-close color="warn">Annuler</button>
        <button
          mat-raised-button
          color="primary"
          class="w-40"
          (click)="mergeAthletes()"
      [disabled]="
        processing ||
        !search[1].selected ||
        !search[2].selected ||
        search[1].selected.id === search[2].selected.id
      "
          >
          @if (!processing) {
            Fusionner les athlètes
          } @else {
            <mat-spinner
              [diameter]="20"
              color="primary"
              class="m-auto"
            ></mat-spinner>
          }
        </button>
      </div>
    </section>
