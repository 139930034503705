<section *transloco="let t" class="w-[400px] max-h-[80vh]">
  <header class="text-center">
    <h3 class="font-bold text-xl">
      @if (data.addResult) {
        {{ t('RESULT_ADD_RESULT') }}
      } @else {
        {{ t('RESULT_EDIT_RESULT') }}
      }
    </h3>
    @if (
      data.addResult &&
      !!data.discipline.parentId &&
      resultFormService.selectedAthletes
    ) {
      <h4 class="mt-2 text-lg text-gray-500">
        {{ data.result.roundNumber }}. {{ data.discipline.name }}
      </h4>
    }
  </header>
  <hr class="my-[15px]" />
  <div>
    @if (!noEventTypeError) {
      @if (data.addResult && !resultFormService.selectedAthletes) {
        <beathletics-competition-result-add-athlete
          [nbAthletes]="data.discipline.eventType?.nb_athlete"
          [isFromCombinedEvents]="!!data.discipline.parentId"
          (athleteSelected)="setAddAthleteData($event)"
        ></beathletics-competition-result-add-athlete>
      } @else {
        <beathletics-competition-result-edit-form
          [type]="formType"
          [form]="$any(resultForm)"
          [precision]="resultPrecision"
          [trials]="trials"
          [hjTrials]="hjTrials"
          [newHeightInput]="$any(hjHeightForm)"
          [usedForNewResult]="data.addResult"
          [isFromCombinedEvents]="isFromCombinedEvents"
          [freezeRank]="{ recalculateRanks, adminDelete }"
        ></beathletics-competition-result-edit-form>
        <mat-slide-toggle
          color="primary"
          class="mt-2 mb-4"
          [(ngModel)]="recalculateRanks"
          >&nbsp;{{ t('RESULT_AUTO_CALCULATE_HEAT_RANK') }}</mat-slide-toggle
        >
      }
      @if (resultForm?.get('points')?.dirty && !data.addResult) {
        <div class="text-sm text-center">
          <em>{{ t('RESULT_POINTS_EDITED_MSG') }}</em>
        </div>
      }
    } @else {
      <p class="text-center py-[10px]">
        {{ t('RESULT_NO_EVENT_TYPE_MSG') }}
      </p>
    }
  </div>
  @if (
    !adminDelete &&
    (!data.addResult || (data.addResult && resultFormService.selectedAthletes))
  ) {
    <div
      class="flex mt-4 pb-4"
      [class.justify-between]="!noEventTypeError"
      [class.justify-center]="noEventTypeError"
    >
      @if (!noEventTypeError) {
        <button mat-raised-button mat-dialog-close color="warn">
          {{ t('BASIC_CANCEL') }}
        </button>
        @if (!isFromCombinedEvents && !data.addResult) {
          <button
            *hasOneOfRoles="[roles.Admin, roles.Admin_lbfa]"
            mat-button
            color="warn"
            (click)="handleDeleteBtn(true)"
          >
            {{ t('BASIC_DELETE') }} (ADMIN)
          </button>
        }
        <button
          mat-raised-button
          mat-dialog-close
          color="primary"
          [disabled]="!resultForm?.dirty || resultForm?.invalid"
          (click)="saveModifications()"
        >
          {{ t('BASIC_REGISTER') }}
        </button>
      } @else {
        <button mat-raised-button mat-dialog-close color="primary">
          {{ t('BASIC_UNDERSTOOD') }} !
        </button>
      }
    </div>
  } @else if (adminDelete) {
    <div class="text-center">
      <em class="text-red-700">{{ t('RESULT_DELETE_WARNING') }}</em>
      <div class="flex justify-between mt-6">
        <button mat-raised-button (click)="handleDeleteBtn(false)">
          {{ t('BASIC_CANCEL') }}
        </button>
        <button
          mat-raised-button
          mat-dialog-close
          color="warn"
          (click)="deleteResult()"
        >
          {{ t('BASIC_DELETE') }}
        </button>
      </div>
    </div>
  }
</section>
